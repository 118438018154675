import { NotificationsService } from './../../services/notifications.service';
import { Project } from './../../_models/Project';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RepositoryService } from 'src/app/services/repository.service';
import { Location, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-projects-form',
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.css']
})
export class ProjectsFormComponent implements OnInit {

  public projectForm: FormGroup;
  startDate: Date;
  endDate: Date;
  project: Project;
  isUpdating: boolean = false;
  id: string;
  public progress: number;

  selectedFiles: FileList;
  formData: FormData;
  fileToUpload: File;

  selectedFiles2: FileList;
  formData2: FormData;
  fileToUpload2: File;

  message: string;
  baseUrl = environment.urlAddress;

  constructor(private location: Location,
    private repo: RepositoryService,
    public datePipe: DatePipe,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.createProjectForm();
    this.id = this.activeRoute.snapshot.params['id'];
    if (this.id != null) {
      this.isUpdating = true;
      this.projectGetById(this.id);
    }
  }

  public createProjectForm() {
    this.startDate = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
    this.endDate = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd"));

    this.projectForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      contractValue: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      clientName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      consultantName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      contractorName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      startDate: new FormControl(this.startDate),
      endDate: new FormControl(this.endDate),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.projectForm.controls[controlName].hasError(errorName);
  }

  public onSubmit = (projectFormValue) => {
    if (this.projectForm.valid) {
      if (this.isUpdating) {
        this.projectUpdate(projectFormValue);
      }
      else {
        this.projectCreate(projectFormValue);
      }
    }
  }

  public onCancel = () => {
    this.router.navigate(['/projects'])
  }

  private projectCreate = (projectFormValue) => {
    let project: Project = {
      name: projectFormValue.name,
      contractValue: projectFormValue.contractValue,
      clientName: projectFormValue.clientName,
      consultantName: projectFormValue.consultantName,
      contractorName: projectFormValue.contractorName,
      startDate: projectFormValue.startDate,
      endDate: projectFormValue.endDate
    }
    this.repo.create('api/projects/projectCreate', project)
      .subscribe(response => {
        this.id = (response as Project).id
        this.uploadClientPage()
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public projectGetById = (id: string) => {
    this.repo.getData('api/projects/projectGetById?id=' + id).subscribe(response => {
      this.project = response as Project
      this.projectForm.patchValue(this.project);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public projectUpdate = (projectFormValue) => {
    let project: Project = {
      id: projectFormValue.id,
      name: projectFormValue.name,
      contractValue: projectFormValue.contractValue,
      clientName: projectFormValue.clientName,
      consultantName: projectFormValue.consultantName,
      contractorName: projectFormValue.contractorName,
      startDate: this.datePipe.transform(projectFormValue.startDate, "yyyy-MM-dd"),
      endDate: this.datePipe.transform(projectFormValue.endDate, "yyyy-MM-dd"),

    }
    this.repo.update('api/projects/projectUpdate', project)
      .subscribe(response => {
       this.uploadClientPage();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public selectClientPage = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload = <File>files[0];
    this.formData = new FormData();
    this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
  }


  public selectContractorPage = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload2 = <File>files[0];
    this.formData2 = new FormData();
    this.formData2.append('file', this.fileToUpload2, this.fileToUpload2.name);
  }


  public uploadClientPage() {
    this.http.post(this.baseUrl + '/api/projects/ClientUploadPhoto?entryId=' + this.id
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          console.log('here3')
          this.uploadContractorPage();
        }
      });
  }

  public uploadContractorPage() {
    this.http.post(this.baseUrl + '/api/projects/ContractorUploadPhoto?entryId=' + this.id
      , this.formData2, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          console.log('here3')
          // this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }
}
