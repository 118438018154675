<!-- Loading Indicator -->
<div *ngIf="!pointsTermsEntriesForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>
<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة اعمال جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="pointsTermsEntriesForm" *ngIf="pointsTermsEntriesForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(pointsTermsEntriesForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">
                    <div class="row">

                        <!-- dropDownLists -->
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>الوحدة</mat-label>
                                        <mat-select formControlName="units" id="units"
                                            (selectionChange)="unitsSelectionChanged($event.value)">
                                            <mat-option [value]="0">--اختر--</mat-option>
                                            <mat-option *ngFor="let unit of unitsList" [value]="unit.id">
                                                {{unit.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row" *ngIf="pointsTermsEntriesForm.value.units != 0">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>النقطة</mat-label>
                                        <mat-select formControlName="points" id="points"
                                            (selectionChange)="pointsSelectionChanged($event.value)">
                                            <mat-option [value]="0">--اختر--</mat-option>
                                            <mat-option *ngFor="let point of pointsList" [value]="point.id">
                                                {{point.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row" *ngIf="pointsTermsEntriesForm.value.points != 0 
                                                     && pointsTermsEntriesForm.value.units != 0">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>المبنى</mat-label>
                                        <mat-select formControlName="buildings" id="buildings"
                                            (selectionChange)="pointsBuildingSelectionChanged($event.value)">
                                            <mat-option [value]="0">--اختر--</mat-option>
                                            <mat-option *ngFor="let building of pointbuildingList"
                                                [value]="building.id">
                                                {{building.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row" *ngIf="pointsTermsEntriesForm.value.points != 0 
                                                    && pointsTermsEntriesForm.value.units != 0
                                                    && pointsTermsEntriesForm.value.buildings != 0">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>القسم</mat-label>
                                        <mat-select formControlName="division" id="division"
                                            (selectionChange)="pointsDivisionSelectionChanged($event.value)">
                                            <mat-option [value]="0">--اختر--</mat-option>
                                            <mat-option *ngFor="let division of pointDivisionList"
                                                [value]="division.id">
                                                {{division.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row" *ngIf="pointsTermsEntriesForm.value.points != 0 
                                                  && pointsTermsEntriesForm.value.units != 0
                                                  && pointsTermsEntriesForm.value.buildings != 0
                                                  && pointsTermsEntriesForm.value.division != 0">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>البند</mat-label>
                                        <mat-select formControlName="terms" id="terms"
                                            (selectionChange)="pointsTermsSelectionChanged($event.value)">
                                            <mat-option [value]="0">--اختر--</mat-option>
                                            <mat-option *ngFor="let term of pointTermsList" [value]="term.id">
                                                {{term.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row" style="font-size: 20px;">
                                <div class="col-md-3">
                                    <mat-label style="color: chocolate;">الكمية القصوى =
                                        {{maxQuantityFromTerms}}</mat-label>
                                </div>

                                <div class="col-md-3">
                                    <mat-label style="color: rgb(34, 236, 16);font-size: 17px;"> الكمية الاعمالة حتى
                                        الان =
                                        {{availableQuantity}}
                                    </mat-label>
                                </div>

                                <div class="col-md-3">
                                    <mat-label style="color: rgb(236, 16, 207);">الكمية المتاحة =
                                        {{quantityMaxAllowedValue}}
                                    </mat-label>
                                </div>

                                <div class="col-md-3">
                                    <mat-label style="color: rgb(16, 104, 236);"> الوحدة: {{termUnit}}
                                    </mat-label>
                                </div>
                            </div>




                        </div>


                        <!-- Texboxes -->
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="الكمية" formControlName="quantity"
                                            id="quantity">
                                        <mat-error *ngIf="hasError('quantity', 'required')">هذا الحقل مطلوب</mat-error>
                                        <mat-error *ngIf="hasError('quantity', 'max')">يجب ان لا يزيد عن الكمية القصوى
                                            للبند - الكمية المتاحة
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="ملاحظات" formControlName="note"
                                            id="note">
                                        <mat-error *ngIf="hasError('note', 'required')">هذا الحقل مطلوب</mat-error>
                                        <mat-error *ngIf="hasError('note', 'maxlength')">الاسم اكثر من 200 حرف
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="col-md-3">
                                        <mat-label>صورة الاستلام</mat-label>
                                        <input type="file" #file accept=".jpg" id="fileUploader"
                                            (change)="selectFile(file.files)">
                                    </div>
                                    <div class="col-md-4">
                                        <span class="upload" *ngIf="progress > 0">
                                            {{progress}}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{message}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!pointsTermsEntriesForm.valid">
                                {{ isUpdating == true ? 'تعديل الاعمال' : 'اضافة الاعمال' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>