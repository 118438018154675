import { Line } from './../../_models/Line';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-lines-form',
  templateUrl: './lines-form.component.html',
  styleUrls: ['./lines-form.component.css']
})
export class LinesFormComponent implements OnInit {
  //Vars ====================================
  lineForm: FormGroup;
  line: Line;
  unitId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }
    
    ngOnInit(): void {
      this.createlinesForm();
      this.id = this.activeRoute.snapshot.params['id'];
      console.log(this.id);
      this.unitId = this.activeRoute.snapshot.params['unitId'];
      if (this.id != null) {
        this.isUpdating = true;
        this.lineGetById(this.id);
      }
    }


    //Form Create

  public createlinesForm() {
    this.lineForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
  }

  public onSubmit = (lineFormValue) => {
    if (this.lineForm.valid) {
      if (this.isUpdating) {
        this.lineUpdate(lineFormValue);
      }
      else {
        this.lineCreate(lineFormValue);
      }
    }
  }

  private lineCreate = (lineFormValue) => {
    let line: Line = {
      name: lineFormValue.name,
      unitId: this.unitId,
    }
    console.log(line);
    this.repo.create('api/lines/lineCreate', line)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public lineGetById = (id: string) => {
    this.repo.getData('api/lines/lineGetById?id=' + id).subscribe(response => {
      this.line = response as Line
      // this.lineForm.patchValue(this.line);
      this.lineForm.get('id').setValue(this.line.id);
      this.lineForm.get('name').setValue(this.line.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public lineUpdate = (lineFormValue) => {
    let line: Line = {
      id: lineFormValue.id,
      name: lineFormValue.name,
      unitId: this.unitId,
    }
    console.log(line);
    this.repo.update('api/lines/lineUpdate', line)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.lineForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/lines-list/' + this.unitId])
  }
}
