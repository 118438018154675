import { Chart } from './../../_models/Chart';
import { Constants } from 'src/app/Constants';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from 'src/app/services/repository.service';
import { ProjectDropDownList } from 'src/app/_models/ProjectDropDownList';
import { RequestModel } from 'src/app/_models/RequestModel';
import { RequestTypeDropDownList } from 'src/app/_models/RequestTypeDropDownList';
import { UserDropDownList } from 'src/app/_models/UserDropDownList';
import { environment } from 'src/environments/environment';
import * as CanvasJS from '../../../assets/canvasjs.min';
@Component({
  selector: 'app-chart-request-list',
  templateUrl: './chart-request-list.component.html',
  styleUrls: ['./chart-request-list.component.css']
})
export class ChartRequestListComponent implements OnInit {
  isLoading = true;
  baseUrl = environment.urlAddress;
  searchText: string;
  requestTypesList: RequestTypeDropDownList[];
  usertype = localStorage.getItem('userType');
  inspectorList: UserDropDownList[];
  projectManagerList: UserDropDownList[];
  projectsList: ProjectDropDownList[];
  enumRequestReponse = Constants.enumRequestReponse;
  chartDataPoints: Chart[];

  //Dates
  dateFrom: string;
  dateTo: string;

  dateSearchFrom: string;
  dateSearchTo: string;

  //Dropdowns
  selectedRequestType: string;
  selectedProject: string;
  defaultSelectedProjects: string;

  //First Chart نسبة الطلبات التي تم الرد عليها والتي لم يتم الرد عليها من مدير المشروع
  $respondedByPm: number;
  $unRespondedByPm: number

  //Second Chart نسبة انواع الردود التي تم الرد عليها من مدير المشروع
  $accepted: number;
  $acceptedWithNotes: number;
  $avoidNotesAndReSubmit: number;
  $rejected: number;

  //Third chart نسبة الانواع 
  $space: number;
  $civil: number;
  $archi: number;
  $mechanical: number;
  $electrical: number;
  $other: number;

  constructor(public datePipe: DatePipe,
    private repo: RepositoryService) { }

  ngOnInit(): void {
    this.projectsGetDropDownList();
    //subadmin - viewer
    if (this.usertype == '1' || this.usertype == '4') {
      this.projectManagersGetDropDownListForSubAdmin();
      this.inspectorsGetDropDownListForSubAdmin();
    }
    else {
      this.inspectorsGetDropDownList();
      this.projectManagersGetDropDownList();
    }
    //pm  and admin - viewer - cont
    if (this.usertype == '0' || this.usertype == '1' || this.usertype == '2' || this.usertype == '3' || this.usertype == '4') {
      this.requestTypeGetDropDownList()
    }

    this.requestsGet(false);

    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()



  }


  //Charts
  public ProjectManagerResponseChart(): void {
    let chart = new CanvasJS.Chart("ProjectManagerResponseChart", {
      theme: "light2",
      animationEnabled: true,
      //exportEnabled: true,
      title: {
        text: "جميع الطلبات",
        fontFamily: "Segoe UI",
      },
      legend: {
        fontFamily: "Segoe UI",
      },
      toolTip: {
        fontFamily: "Segoe UI",
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "({y}) - (#percent%) :<b>{name}</b>",
        indexLabel: "#percent% ({y}) - {name}",
        dataPoints: [
          { y: this.$respondedByPm, name: "تم الرد" },
          { y: this.$unRespondedByPm, name: "لم يتم الرد" },
        ]
      }],

    });

    chart.render();
  }

  public ProjectManagerResponseTypeChart(): void {
    let chart = new CanvasJS.Chart("ProjectManagerResponseTypeChart", {
      theme: "light2",
      animationEnabled: true,
      // exportEnabled: true,
      title: {
        text: "حالة الطلب",
        fontFamily: "Segoe UI",
      },
      legend: {
        fontFamily: "Segoe UI",
      },
      toolTip: {
        fontFamily: "Segoe UI",
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "({y}) - (#percent%) :<b>{name}</b>",
        indexLabel: "#percent% ({y}) - {name}",
        dataPoints: [
          { y: this.$accepted, name: "معتمد" },
          { y: this.$acceptedWithNotes, name: "معتمد بملاحظات" },
          { y: this.$avoidNotesAndReSubmit, name: "تفادي الملاحظات واعادة التقديم" },
          { y: this.$rejected, name: "مرفوض" },
        ]
      }],

    });

    chart.render();
  }

  public TypesPercentageChart(): void {
    let chart = new CanvasJS.Chart("TypesPercentageChart", {
      theme: "light2",
      animationEnabled: true,
      // exportEnabled: true,
      title: {
        text: "التصنيف",
        fontFamily: "Segoe UI",
      },
      legend: {
        fontFamily: "Segoe UI",
      },
      toolTip: {
        fontFamily: "Segoe UI",
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "({y}) - (#percent%) :<b>{name}</b>",
        indexLabel: "#percent% ({y}) - {name}",
        dataPoints: [
          { y: this.$space, name: "مساحة" },
          { y: this.$civil, name: "مدني" },
          { y: this.$archi, name: "معماري" },
          { y: this.$mechanical, name: "ميكانيكا" },
          { y: this.$electrical, name: "كهرباء" },
          { y: this.$other, name: "اخرى" },
        ]
      }],

    });

    chart.render();
  }

  public PointsChart(points: Chart[]): void {
    let chart = new CanvasJS.Chart("PointsChart", {
      theme: "light2",
      animationEnabled: true,
      indexLabelPlacement: "inside",
      indexLabelMaxWidth: 70,
      indexLabelFormatter: function (e) {
        var label = e.dataPoint.label + ": " + e.dataPoint.y + " Total: " + e.total;
        return label;
      },
      // exportEnabled: true,
      title: {
        text: "نسبة المواقع",
        fontFamily: "Segoe UI",
      },
      legend: {
        fontFamily: "Segoe UI",
      },
      toolTip: {
        fontFamily: "Segoe UI",
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "({y}) - (#percent%) :<b>{name}</b>",
        indexLabel: "#percent% ({y}) - {name}",
        dataPoints: points
      }],

    });

    chart.render();
  }




  //Database
  public projectsGetDropDownList = () => {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/projects/ProjectsGetDropDownListByUserId?userId=' + userId + '&userType=' + this.usertype)
      .subscribe(response => {
        this.projectsList = response as ProjectDropDownList[]
        //this.defaultSelectedProjects = this.projectsList[0].id;
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public projectManagersGetDropDownListForSubAdmin() {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/users/projectManagersGetDropDownListForSubAdmin?subAdminId=' + userId)
      .subscribe(response => {
        this.projectManagerList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public inspectorsGetDropDownListForSubAdmin() {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/users/inspectorsGetDropDownListForSubAdmin?subAdminId=' + userId)
      .subscribe(response => {
        this.inspectorList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public inspectorsGetDropDownList = () => {
    this.repo.getData('api/users/inspectorsGetDropDownList')
      .subscribe(response => {
        this.inspectorList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public projectManagersGetDropDownList = () => {
    this.repo.getData('api/users/projectManagersGetDropDownList')
      .subscribe(response => {
        this.projectManagerList = response as UserDropDownList[]

        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public requestTypeGetDropDownList = () => {
    this.repo.getData('api/requestTypes/requestTypesGetDropDownList?userId=' + localStorage.getItem('userId'))
      .subscribe(response => {
        this.requestTypesList = response as RequestTypeDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }


  public requestsGet = (showAll:boolean) => {
    this.isLoading = true;
    let userId: string = localStorage.getItem('userId');


    let url = '';
    if (showAll) {
      url = 'api/requests/requestsGet?userId=' + userId + '&userType=' + this.usertype + '&showAll=' + showAll
    }
    else {
      url = 'api/requests/requestsGet?userId=' + userId + '&userType=' + this.usertype + '&showAll=' + showAll
    }

    this.repo.getData(url)
      .subscribe(response => {
        console.log(response);
        let requests = response as RequestModel[]
        this.filterForProjectManagerResponseChart(requests);
        this.filterForProjectManagerResponseTypeChart(requests);
        this.filterTypesPercentageChart(requests);
        this.filterForPointsChart(requests);

        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }

  private filterForProjectManagerResponseChart(requests: RequestModel[]) {
    //ProjectManagerResponseChart
    this.$unRespondedByPm = requests.filter(x => x.projectManagerResponse === null).length;
    console.log('this.$unRespondedByPm=', this.$unRespondedByPm);

    this.$respondedByPm = requests.filter(x => x.projectManagerResponse !== null).length;
    console.log('this.$respondedByPm=', this.$respondedByPm);
    this.ProjectManagerResponseChart();
  }

  private filterForProjectManagerResponseTypeChart(requests: RequestModel[]) {
    //ProjectManagerResponseTypeChart
    this.$accepted = requests.filter(x => x.projectManagerResponse !== null && x.projectManagerResponse === 0).length;
    this.$acceptedWithNotes = requests.filter(x => x.projectManagerResponse !== null && x.projectManagerResponse === 1).length;
    this.$avoidNotesAndReSubmit = requests.filter(x => x.projectManagerResponse !== null && x.projectManagerResponse === 2).length;
    this.$rejected = requests.filter(x => x.projectManagerResponse !== null && x.projectManagerResponse === 3).length;
    this.ProjectManagerResponseTypeChart();
  }

  private filterTypesPercentageChart(requests: RequestModel[]) {
    //TypesPercentageChart
    this.$space = requests.filter(x => x.engineeringType === 0).length;
    this.$civil = requests.filter(x => x.engineeringType === 1).length;
    this.$archi = requests.filter(x => x.engineeringType === 2).length;
    this.$mechanical = requests.filter(x => x.engineeringType === 3).length;
    this.$electrical = requests.filter(x => x.engineeringType === 4).length;
    this.$other = requests.filter(x => x.engineeringType === 5).length;
    this.TypesPercentageChart();
  }

  private filterForPointsChart(requests: RequestModel[]) {
    //PointsChart
    let charts = [] as Chart[];
    var pointNames = requests.filter(x => x.pointName !== null).map(y => y.pointName)
    pointNames.forEach(element => {
      let chart = {} as Chart;
      chart.name = element;
      chart.y = pointNames.filter(x => x === element).length;
      charts.push(chart);
    });

    //Distinct values
    var dataPoints = [];
    charts.filter(function (item) {
      var i = dataPoints.findIndex(x => (x.name == item.name && x.y == item.y));
      if (i <= -1) {
        dataPoints.push(item);
      }
      return null;
    });
    this.PointsChart(dataPoints);
  }




  public requestsSearch = (search: string) => {
    let userId = localStorage.getItem('userId');
    console.log(this.dateFrom);
    if (search == null) {
      search = '';
    }
    if (this.selectedRequestType == null) {
      this.selectedRequestType = '0';
    }
    if (this.selectedProject == null) {
      this.selectedProject = '0';
    }
    if (this.dateSearchFrom == null) {
      this.dateSearchFrom = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    if (this.dateSearchTo == null) {
      this.dateSearchTo = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    this.isLoading = true;
    this.repo.getData('api/requests/requestsSearch?query=' + search + '&requestTypeId='
      + this.selectedRequestType + '&dateFrom=' + this.dateSearchFrom + '&dateTo='
      + this.dateSearchTo + '&userId=' + userId + '&userType=' + this.usertype + '&projectId=' + this.selectedProject)
      .subscribe(response => {
        let requests = response as RequestModel[];

        this.filterForProjectManagerResponseChart(requests);
        this.filterForProjectManagerResponseTypeChart(requests);
        this.filterTypesPercentageChart(requests);
        this.filterForPointsChart(requests);

        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })

  }


  public requestTypeGetDropDownListByProjectId = (projectId: string) => {
    this.repo.getData('api/requestTypes/RequestTypesGetDropDownListByProjectId?projectId=' + projectId)
      .subscribe(response => {
        this.requestTypesList = response as RequestTypeDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //search form
  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.requestsSearch(value)
  }

  public clearSearch = (value: string) => {
    this.requestsGet(true);
  }

  changeType(event: any) {
    console.log(event);
    this.selectedRequestType = event;
  }

  changeProject(event: any) {
    console.log(event);
    this.selectedProject = event;
    this.requestTypeGetDropDownListByProjectId(event);
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
    console.log(this.dateSearchFrom);
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }

}
