import { HomeComponent } from './_home/home/home.component';
import { MapComponent } from './_map/map/map.component';
import { WeeklyReportFormComponent } from './reports-weekly/weekly-report-form/weekly-report-form.component';
import { PipeTermsTypesFormComponent } from './pipeTermsTypes/pipe-terms-types-form/pipe-terms-types-form.component';
import { PipeTermsTypesListComponent } from './pipeTermsTypes/pipe-terms-types-list/pipe-terms-types-list.component';
import { RoomsEntriesFormComponent } from './roomEntries/rooms-entries-form/rooms-entries-form.component';
import { RoomsEntriesListComponent } from './roomEntries/rooms-entries-list/rooms-entries-list.component';
import { RoomsStationsFormComponent } from './roomStations/rooms-stations-form/rooms-stations-form.component';
import { RoomsStationsListComponent } from './roomStations/rooms-stations-list/rooms-stations-list.component';
import { RoomsTermsFormComponent } from './roomsTerms/rooms-terms-form/rooms-terms-form.component';
import { RoomsTermsListComponent } from './roomsTerms/rooms-terms-list/rooms-terms-list.component';
import { RoomsDivisionsFormComponent } from './roomDivisions/rooms-divisions-form/rooms-divisions-form.component';
import { RoomsDivisionsListComponent } from './roomDivisions/rooms-divisions-list/rooms-divisions-list.component';
import { PipeTermsEntriesFormComponent } from './terms/pipe-terms-entries-form/pipe-terms-entries-form.component';
import { PipeTermsEntriesListComponent } from './terms/pipe-terms-entries-list/pipe-terms-entries-list.component';
import { PipesTermsFormComponent } from './pipesTerms/pipes-terms-form/pipes-terms-form.component';
import { PipesTermsListComponent } from './pipesTerms/pipes-terms-list/pipes-terms-list.component';
import { PipesDivisionsFormComponent } from './pipesDivisions/pipes-divisions-form/pipes-divisions-form.component';
import { PipesDivisionsListComponent } from './pipesDivisions/pipes-divisions-list/pipes-divisions-list.component';
import { RoomTypesFormComponent } from './room-types/room-types-form/room-types-form.component';
import { RoomTypesListComponent } from './room-types/room-types-list/room-types-list.component';
import { LinesCoordinatesFormComponent } from './lines-coordinates/lines-coordinates-form/lines-coordinates-form.component';
import { LinesCoordinatesListComponent } from './lines-coordinates/lines-coordinates-list/lines-coordinates-list.component';
import { LinesDivisionsFormComponent } from './lines-divisions/lines-divisions-form/lines-divisions-form.component';
import { LinesDivisionsListComponent } from './lines-divisions/lines-divisions-list/lines-divisions-list.component';
import { LinesFormComponent } from './lines/lines-form/lines-form.component';
import { LinesListComponent } from './lines/lines-list/lines-list.component';
import { TimeFrameFormComponent } from './timeFrame/time-frame-form/time-frame-form.component';
import { TimeFrameListComponent } from './timeFrame/time-frame-list/time-frame-list.component';
import { ChartRequestListComponent } from './_charts/chart-request-list/chart-request-list.component';
import { PointTermsListComponent } from './terms/point-terms-list/point-terms-list.component';
import { PointTermsEntriesFormComponent } from './terms/point-terms-entries-form/point-terms-entries-form.component';
import { PointsTermsFormComponent } from './terms/points-terms-form/points-terms-form.component';
import { PointsTermsComponent } from './terms/points-terms/points-terms.component';
import { PointsDivisionsFormComponent } from './divisions/points-divisions-form/points-divisions-form.component';
import { PointsDivisionsComponent } from './divisions/points-divisions/points-divisions.component';
import { PointsBuildingFormComponent } from './pointsbuilding/points-building-form/points-building-form.component';
import { PointsBuildingComponent } from './pointsbuilding/points-building/points-building.component';
import { PointsFormComponent } from './points/points-form/points-form.component';
import { PointsListComponent } from './points/points-list/points-list.component';
import { RequestAttachmentsComponent } from './request-attachments/request-attachments.component';
import { AuthGuard } from './services/auth.guard';
import { RequestsListComponent } from './requests/requests-list/requests-list.component';
import { LoginComponent } from './login/login.component';
import { RequestsFormComponent } from './requests/requests-form/requests-form.component';
import { UsersFormComponent } from './users/users-form/users-form.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { RequestTypesFormComponent } from './requestTypes/request-types-form/request-types-form.component';
import { RequestTypesListComponent } from './requestTypes/request-types-list/request-types-list.component';
import { UnitsListComponent } from './units/units-list/units-list.component';
import { ProjectsFormComponent } from './projects/projects-form/projects-form.component';
import { ProjectsListComponent } from './projects/projects-list/projects-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { UnitsFormComponent } from './units/units-form/units-form.component';
import { GDashboardLinksComponent} from './gdashboard-links/gdashboard-links.component'
import { ReportViewerComponent } from './report-viewer/report-viewer.component';


const routes: Routes = [
  { path: 'projects', component: ProjectsListComponent, canActivate: [AuthGuard] },
  { path: 'projects-form', component: ProjectsFormComponent, canActivate: [AuthGuard] },
  { path: 'projects-form/:id', component: ProjectsFormComponent, canActivate: [AuthGuard] },

  { path: 'request-types/:projectId', component: RequestTypesListComponent, canActivate: [AuthGuard] },
  { path: 'request-types-form/:projectId', component: RequestTypesFormComponent, canActivate: [AuthGuard] },
  { path: 'request-types-form/:id/:projectId', component: RequestTypesFormComponent, canActivate: [AuthGuard] },


  { path: 'pipe-terms-types-list/:projectId', component: PipeTermsTypesListComponent, canActivate: [AuthGuard] },
  { path: 'pipe-terms-types-form/:projectId', component: PipeTermsTypesFormComponent, canActivate: [AuthGuard] },
  { path: 'pipe-terms-types-form/:id/:projectId', component: PipeTermsTypesFormComponent, canActivate: [AuthGuard] },


  // { path: 'time-frame-list/:projectId', component: TimeFrameListComponent },
  // { path: 'time-frame-form/:projectId', component: TimeFrameFormComponent },

  { path: 'users', component: UsersListComponent, canActivate: [AuthGuard] },
  { path: 'users-form', component: UsersFormComponent, canActivate: [AuthGuard] },
  { path: 'users-form/:id', component: UsersFormComponent, canActivate: [AuthGuard] },


  { path: 'requests', component: RequestsListComponent, canActivate: [AuthGuard] },
  { path: 'requests-form', component: RequestsFormComponent, canActivate: [AuthGuard] },
  { path: 'requests-form/:id', component: UsersFormComponent, canActivate: [AuthGuard] },
  { path: 'request-attachments/:id', component: RequestAttachmentsComponent, canActivate: [AuthGuard] },

  { path: 'gdashboard-links', component: GDashboardLinksComponent, canActivate: [AuthGuard] },

  { path: 'units/:id', component: UnitsListComponent, canActivate: [AuthGuard] },
  { path: 'units-form/:projectId', component: UnitsFormComponent, canActivate: [AuthGuard] },
  { path: 'units-form/:id/:projectId', component: UnitsFormComponent, canActivate: [AuthGuard] },

  { path: 'points/:id', component: PointsListComponent, canActivate: [AuthGuard] },
  { path: 'points-form/:unitId', component: PointsFormComponent, canActivate: [AuthGuard] },
  { path: 'points-form/:id/:unitId', component: PointsFormComponent, canActivate: [AuthGuard] },


  { path: 'points-buildings/:id', component: PointsBuildingComponent, canActivate: [AuthGuard] },
  { path: 'points-buildings-form/:pointId', component: PointsBuildingFormComponent, canActivate: [AuthGuard] },
  { path: 'points-buildings-form/:id/:pointId', component: PointsBuildingFormComponent, canActivate: [AuthGuard] },

  { path: 'points-divisions/:id', component: PointsDivisionsComponent, canActivate: [AuthGuard] },
  { path: 'points-divisions-form/:pointBuildingId', component: PointsDivisionsFormComponent, canActivate: [AuthGuard] },
  { path: 'points-divisions-form/:id/:pointBuildingId', component: PointsDivisionsFormComponent, canActivate: [AuthGuard] },

  { path: 'points-terms/:id', component: PointsTermsComponent, canActivate: [AuthGuard] },
  { path: 'points-terms-form/:pointDivisionId', component: PointsTermsFormComponent, canActivate: [AuthGuard] },
  { path: 'points-terms-form/:id/:pointDivisionId', component: PointsTermsFormComponent, canActivate: [AuthGuard] },


  { path: 'points-terms-entries-list', component: PointTermsListComponent, canActivate: [AuthGuard] },
  { path: 'points-terms-entries-form', component: PointTermsEntriesFormComponent, canActivate: [AuthGuard] },
  { path: 'points-terms-entries-form/:id', component: PointTermsEntriesFormComponent, canActivate: [AuthGuard] },

  { path: 'time-frame-list/:projectId', component: TimeFrameListComponent, canActivate: [AuthGuard] },
  { path: 'time-frame-form/:projectId', component: TimeFrameFormComponent, canActivate: [AuthGuard] },
  //{ path: 'time-frame-form/:id', component: TimeFrameFormComponent},


  { path: 'lines-list/:id', component: LinesListComponent, canActivate: [AuthGuard] },
  { path: 'lines-form/:unitId', component: LinesFormComponent, canActivate: [AuthGuard] },
  { path: 'lines-form/:id/:unitId', component: LinesFormComponent, canActivate: [AuthGuard] },


  { path: 'lines-divisions-list/:id', component: LinesDivisionsListComponent, canActivate: [AuthGuard] },
  { path: 'lines-divisions-form/:lineId', component: LinesDivisionsFormComponent, canActivate: [AuthGuard] },
  { path: 'lines-divisions-form/:id/:lineId', component: LinesDivisionsFormComponent, canActivate: [AuthGuard] },


  { path: 'lines-coordinates-list/:id', component: LinesCoordinatesListComponent, canActivate: [AuthGuard] },
  { path: 'lines-coordinates-form/:lineId', component: LinesCoordinatesFormComponent, canActivate: [AuthGuard] },
  { path: 'lines-coordinates-form/:id/:lineId', component: LinesCoordinatesFormComponent, canActivate: [AuthGuard] },


  { path: 'room-types-list/:id', component: RoomTypesListComponent, canActivate: [AuthGuard] },
  { path: 'room-types-form/:lineId', component: RoomTypesFormComponent, canActivate: [AuthGuard] },
  { path: 'room-types-form/:id/:lineId', component: RoomTypesFormComponent, canActivate: [AuthGuard] },

  { path: 'pipes-divisions-list/:id', component: PipesDivisionsListComponent, canActivate: [AuthGuard] },
  { path: 'pipes-divisions-form/:lineId', component: PipesDivisionsFormComponent, canActivate: [AuthGuard] },
  { path: 'pipes-divisions-form/:id/:lineId', component: PointsFormComponent, canActivate: [AuthGuard] },

  { path: 'pipes-terms-list/:id', component: PipesTermsListComponent, canActivate: [AuthGuard] },
  { path: 'pipes-terms-form/:pipeDivisionId', component: PipesTermsFormComponent, canActivate: [AuthGuard] },
  { path: 'pipes-terms-form/:id/:pipeDivisionId', component: PipesTermsFormComponent, canActivate: [AuthGuard] },

  { path: 'pipes-terms-entries-list', component: PipeTermsEntriesListComponent, canActivate: [AuthGuard] },
  { path: 'pipes-terms-entries-form', component: PipeTermsEntriesFormComponent, canActivate: [AuthGuard] },
  { path: 'pipes-terms-entries-form/:id', component: PipeTermsEntriesFormComponent, canActivate: [AuthGuard] },


  { path: 'rooms-divisions-list/:id', component: RoomsDivisionsListComponent, canActivate: [AuthGuard] },
  { path: 'rooms-divisions-form/:lineId', component: RoomsDivisionsFormComponent, canActivate: [AuthGuard] },
  { path: 'rooms-divisions-form/:id/:lineId', component: RoomsDivisionsFormComponent, canActivate: [AuthGuard] },


  { path: 'rooms-terms-list/:id', component: RoomsTermsListComponent, canActivate: [AuthGuard] },
  { path: 'rooms-terms-form/:roomDivisionId', component: RoomsTermsFormComponent, canActivate: [AuthGuard] },
  { path: 'rooms-terms-form/:id/:roomDivisionId', component: RoomsTermsFormComponent, canActivate: [AuthGuard] },


  { path: 'rooms-stations-list/:id', component: RoomsStationsListComponent, canActivate: [AuthGuard] },
  { path: 'rooms-stations-form/:roomTypeId', component: RoomsStationsFormComponent, canActivate: [AuthGuard] },
  { path: 'rooms-stations-form/:id/:roomTypeId', component: RoomsStationsFormComponent, canActivate: [AuthGuard] },

  { path: 'rooms-entries-list', component: RoomsEntriesListComponent, canActivate: [AuthGuard] },
  { path: 'rooms-entries-form', component: RoomsEntriesFormComponent, canActivate: [AuthGuard] },
  { path: 'rooms-entries-form/:id', component: RoomsEntriesFormComponent, canActivate: [AuthGuard] },



  //Charts
  { path: 'chart-request-list', component: ChartRequestListComponent, canActivate: [AuthGuard] },

  { path: 'dashboard-report/:id', component: ReportViewerComponent, canActivate: [AuthGuard] },

  //Reports
  { path: 'weekly-report-form', component: WeeklyReportFormComponent, canActivate: [AuthGuard] },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },


  { path: 'login', component: LoginComponent },


  { path: '', redirectTo: '/login', pathMatch: 'full' },

  // { path: '404', component: NotFoundComponent},
  // { path: '**', redirectTo: '/404', pathMatch: 'full'}

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
