import { RoomStation } from './../../_models/RoomStation';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-rooms-stations-form',
  templateUrl: './rooms-stations-form.component.html',
  styleUrls: ['./rooms-stations-form.component.css']
})
export class RoomsStationsFormComponent implements OnInit {

  //Vars ====================================
  roomsstationsForm: FormGroup;
  roomsstation: RoomStation;
  roomTypeid: string;
  id: string;
  isUpdating: boolean = false;

  //roomstationsDropDownList: roomstationDropDownList[] = [];

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    // this.roomsstationsGetDropDownList();
    this.createroomsstationssForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.roomTypeid = this.activeRoute.snapshot.params['roomTypeId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.roomsstationsGetById(this.id);
    }
  }


  public createroomsstationssForm() {
    this.roomsstationsForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),   
    });
  }

  public onSubmit = (roomsstationsFormValue) => {
    if (this.roomsstationsForm.valid) {
      if (this.isUpdating) {
        this.roomsstationsUpdate(roomsstationsFormValue);
      }
      else {
        this.roomsstationsCreate(roomsstationsFormValue);
      }
    }
  }

  private roomsstationsCreate = (roomsstationsFormValue) => {
    let roomsstations: RoomStation = {
      name: roomsstationsFormValue.name,
      roomTypeId: this.roomTypeid,
    }
    console.log(roomsstations);
    this.repo.create('api/RoomStation/RoomStationCreate', roomsstations)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  // public roomsstationsGetDropDownList = () => {
  //   this.repo.getData('api/roomstations/roomstationsGetDropDownList')
  //     .subscribe(response => {
  //       this.roomstationsDropDownList = response as roomstationDropDownList[];
  //       console.log('roomstationsDropDownList',this.roomstationsDropDownList)
  //       this.createroomsstationssForm();
  //       console.log(response);

  //     }, (error) => {
  //       console.log(error);
  //     })
  // }


  public roomsstationsGetById = (id: string) => {
    this.repo.getData('api/RoomStation/RoomStationGetById?id=' + id).subscribe(response => {
      this.roomsstation = response as RoomStation
      // this.roomsstationsForm.patchValue(this.roomsstations);
      this.roomsstationsForm.get('id').setValue(this.roomsstation.id);
      this.roomsstationsForm.get('name').setValue(this.roomsstation.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public roomsstationsUpdate = (roomsstationsFormValue) => {
    let roomsstations: RoomStation = {
      id: roomsstationsFormValue.id,
      name: roomsstationsFormValue.name,
      roomTypeId: this.roomTypeid,   
    }
    console.log(roomsstations);
    this.repo.update('api/RoomStation/RoomStationUpdate', roomsstations)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.roomsstationsForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/rooms-stations-list/' + this.roomTypeid])
  }

}
