<div class="gradient-Header">
    <div class="gradient-child">
        <h3>أعمال المحطات والخزانات</h3>
    </div>
</div>



<div fxLayout="row" fxLayoutAlign="space-around center" class="fx-wrap">
  <div class="col-md-2">
    <mat-form-field>
        <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text" placeholder="بحث">
    </mat-form-field>
  </div>
    <div class="col-md-2">
        <mat-form-field >
            <mat-label>المبنى</mat-label>
            <mat-select (selectionChange)="filterByBuilding($event.value)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let buildingName of pointBuildingNames" [value]="buildingName">
                    {{ buildingName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field >
            <mat-label>الوحدة</mat-label>
            <mat-select (selectionChange)="filterByUnit($event.value)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let unit of unitNames" [value]="unit">
                    {{ unit }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field >
            <mat-label>النقطة</mat-label>
            <mat-select (selectionChange)="filterByPoint($event.value)">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let point of pointNames" [value]="point">
                    {{ point }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
                (dateChange)="changeFromDate($event.value)" placeholder="من">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo"
                (dateChange)="changeToDate($event.value)" placeholder="الى">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

<div class="d-flex mb-3" >
  <button style="height: 34px;
    margin-top: 12px;" mat-raised-button (click)="search(searchText)" color="primary">
    <mat-icon>search</mat-icon>
    بحث
</button>

<button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
    <mat-icon>search</mat-icon>
    عرض الكل
</button>
</div>

</div>


<div class="row">
    <div class="col-md-3 mb-2">
        <button [routerLink]="['/points-terms-entries-form']" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>
</div>


<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->

        <ng-container matColumnDef="unitName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>الوحدة</th>
            <td mat-cell *matCellDef="let element"> {{element.unitName}} </td>
        </ng-container>

        <ng-container matColumnDef="pointName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>النقطة</th>
            <td mat-cell *matCellDef="let element"> {{element.pointName}} </td>
        </ng-container>

        <ng-container matColumnDef="pointBuildingName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> المبنى </th>
            <td mat-cell *matCellDef="let element"> {{element.pointBuildingName}} </td>
        </ng-container>


        <ng-container matColumnDef="pointDivisionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> القسم </th>
            <td mat-cell *matCellDef="let element"> {{element.pointDivisionName}} </td>
        </ng-container>


        <ng-container matColumnDef="pointTermName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> البند </th>
            <td mat-cell *matCellDef="let element"> {{element.pointTermName}} </td>
        </ng-container>

        <ng-container matColumnDef="pointTermPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> قيمة البند </th>
            <td mat-cell *matCellDef="let element"> {{element.pointTermPrice}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> الكمية </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> الاجمالي </th>
            <td mat-cell *matCellDef="let element"> {{(element.quantity * element.pointTermPrice) | number:'0.0-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> الوحدة </th>
            <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
        </ng-container>

        <ng-container matColumnDef="inspectorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم المسجل </th>
            <td mat-cell *matCellDef="let element"> {{element.inspectorName}} </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ملاحظات </th>
            <td mat-cell *matCellDef="let element"> {{element.note}} </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ناريخ الاضافة </th>
            <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'yyyy-MM-dd'}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="photo">
            <th mat-header-cell *matHeaderCellDef>تحميل</th>
            <td mat-cell *matCellDef="let element">
                <a class="btn btn-info" target="_blank" href="{{baseUrl+'/EntryPhotos/'}}{{element.id}}.jpg"
                    download="fileName">
                    تحميل
                </a>
            </td>
        </ng-container>



        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>صورة الاستلام</th>
            <td mat-cell *matCellDef="let element">
                <a color="warn" href="{{baseUrl+'/EntryPhotos/'}}{{fg.value.id}}.jpg" download="fileName">
                    <mat-icon class="mat-18">download</mat-icon>
                </a>
            </td>
        </ng-container> -->


        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <button mat-raised-button class="green-btn" (click)="exportToExcel()">Export to Excel</button>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>
