import { RoomType } from './../../_models/RoomType';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-room-types-form',
  templateUrl: './room-types-form.component.html',
  styleUrls: ['./room-types-form.component.css']
})
export class RoomTypesFormComponent implements OnInit {

  //Vars ====================================
  roomTypeForm: FormGroup;
  roomType: RoomType;
  lineId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.createroomTypesForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.lineId = this.activeRoute.snapshot.params['lineId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.roomTypeGetById(this.id);
    }
  }


  //Form Create

  public createroomTypesForm() {
    this.roomTypeForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      price: new FormControl(0, [Validators.required,Validators.min(1)]),
    });
  }

  public onSubmit = (roomTypeFormValue) => {
    if (this.roomTypeForm.valid) {
      if (this.isUpdating) {
        this.roomTypeUpdate(roomTypeFormValue);
      }
      else {
        this.roomTypeCreate(roomTypeFormValue);
      }
    }
  }

  private roomTypeCreate = (roomTypeFormValue) => {
    let roomType: RoomType = {
      name: roomTypeFormValue.name,
      price: roomTypeFormValue.price,
      lineId: this.lineId,
    }
    console.log(roomType);
    this.repo.create('api/roomTypes/roomTypeCreate', roomType)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public roomTypeGetById = (id: string) => {
    this.repo.getData('api/roomTypes/roomTypeGetById?id=' + id).subscribe(response => {
      this.roomType = response as RoomType
      // this.roomTypeForm.patchValue(this.roomType);
      this.roomTypeForm.get('id').setValue(this.roomType.id);
      this.roomTypeForm.get('name').setValue(this.roomType.name);
      this.roomTypeForm.get('price').setValue(this.roomType.price);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public roomTypeUpdate = (roomTypeFormValue) => {
    let roomType: RoomType = {
      id: roomTypeFormValue.id,
      name: roomTypeFormValue.name,
      price: roomTypeFormValue.price,
      lineId: this.lineId,
    }
    console.log(roomType);
    this.repo.update('api/roomTypes/roomTypeUpdate', roomType)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.roomTypeForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/room-types-list/' + this.lineId])
  }

}
