import { RequestType } from './../../_models/RequestType';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectDropDownList } from 'src/app/_models/ProjectDropDownList';
import { RepositoryService } from 'src/app/services/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-request-types-form',
  templateUrl: './request-types-form.component.html',
  styleUrls: ['./request-types-form.component.css']
})
export class RequestTypesFormComponent implements OnInit {

  //Vars ====================================
  requestTypeForm: FormGroup;
  requestType: RequestType;
  isUpdating: boolean = false;
  id: string;
  projectsList: ProjectDropDownList[];
  projectId: string;

  constructor(private location: Location,
    private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.projectGetDropDownList();
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');
    this.id = this.activeRoute.snapshot.params['id'];
    // if (this.id != null) {
    //   this.isUpdating = true;
    //   this.requestTypeGetById(this.id);
    // }
  }

  ngAfterViewInit(): void {

  }


  //Form Create

  public createRequestTypesForm() {
    this.requestTypeForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      maxApprovalPeriod: new FormControl(5, [Validators.required]),
      project: new FormControl(this.projectsList[0].id)
    });
  }

  public onSubmit = (requestTypeFormValue) => {
    if (this.requestTypeForm.valid) {
      if (this.isUpdating) {
        this.requestTypeUpdate(requestTypeFormValue);
      }
      else {
        this.requestTypeCreate(requestTypeFormValue);
      }
    }
  }

  //Database ===================================
  public projectGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList').subscribe(response => {
      this.projectsList = response as ProjectDropDownList[]
      this.createRequestTypesForm();
      if (this.id != null) {
        this.isUpdating = true;
        this.requestTypeGetById(this.id);
      }
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }



  private requestTypeCreate = (requestTypeFormValue) => {
    let requestType: RequestType = {
      name: requestTypeFormValue.name,
      maxApprovalPeriod: requestTypeFormValue.maxApprovalPeriod,
      projectId: this.projectId,
    }
    console.log(requestType);
    this.repo.create('api/requestTypes/requestTypeCreate', requestType)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public requestTypeGetById = (id: string) => {
    this.repo.getData('api/requestTypes/requestTypeGetById?id=' + id).subscribe(response => {
      this.requestType = response as RequestType

      // this.requestTypeForm.patchValue(this.requestType);
      this.requestTypeForm.get('id').setValue(this.requestType.id);
      this.requestTypeForm.get('name').setValue(this.requestType.name);
      this.requestTypeForm.get('project').setValue(this.requestType.projectId);
      this.requestTypeForm.get('maxApprovalPeriod').setValue(this.requestType.maxApprovalPeriod);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public requestTypeUpdate = (requestTypeFormValue) => {
    let requestType: RequestType = {
      id: requestTypeFormValue.id,
      name: requestTypeFormValue.name,
      maxApprovalPeriod: requestTypeFormValue.maxApprovalPeriod,
      projectId: this.projectId,
    }
    console.log(requestType);
    this.repo.update('api/requestTypes/requestTypeUpdate', requestType)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.requestTypeForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/request-types/' + this.projectId])
  }
}
