<div class="gradient-Header">
  <div class="gradient-child">
    <h3>أعمال الشبكات والخطوط</h3>
  </div>
</div>



<div class="row" style="margin-top: 10px; justify-content: space-around;">
  <div class="col-md-2">
    <mat-form-field>
      <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text" placeholder="بحث">
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <mat-form-field>
      <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom" (dateChange)="changeFromDate($event.value)"
        placeholder="من">
      <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
      <mat-datepicker #dp1 disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <mat-form-field>
      <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo" (dateChange)="changeToDate($event.value)"
        placeholder="الى">
      <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
      <mat-datepicker #dp2 disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <button style="margin-top: 10px;" mat-raised-button (click)="search(searchText)" color="primary">
      <mat-icon>search</mat-icon>
      بحث
    </button>
  </div>
  <div class="col-md-2">

    <button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
      <mat-icon>search</mat-icon>
      عرض الكل
    </button>
  </div>

</div>

<div class="row mt-2" style="justify-content: space-around;">
  <!-- قوائم منسدلة للتصفية -->
  <div class="col-md-2">
    <!-- <mat-form-field *ngIf="uniqueLocations?.length > 0" class="transparent-field">
      <mat-label>المنطقة</mat-label>
      <mat-select [(ngModel)]="selectedLocation" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let location of uniqueLocations" [value]="location">{{ location }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>المنطقة</mat-label>
      <mat-select (selectionChange)="filterByUnit($event.value)">
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let location of uniqueLocations" [value]="location">
          {{ location }}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </div>
  <div class="col-md-2">
    <!-- <mat-form-field >
      <mat-label>الخط</mat-label>
      <mat-select [(ngModel)]="selectedLines" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let line of uniqueLines" [value]="line">{{ line }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>الخط</mat-label>
      <mat-select (selectionChange)="filterByLine($event.value)">
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let line of uniqueLines" [value]="line">
          {{ line }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <!-- <mat-form-field>
      <mat-label>القسم</mat-label>
      <mat-select [(ngModel)]="selectedDivision" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let division of uniqueDivisions" [value]="division">{{ division }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>القسم</mat-label>
      <mat-select (selectionChange)="filterBydivision($event.value)">
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let division of uniqueDivisions" [value]="division">
          {{ division }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <!-- <mat-form-field>
      <mat-label>البند</mat-label>
      <mat-select [(ngModel)]="selectedpipeTermName" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let pipe of uniquepipeTermName" [value]="pipe">{{ pipe }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>البند</mat-label>
      <mat-select (selectionChange)="filterByPipe($event.value)">
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let pipe of uniquepipeTermName" [value]="pipe">
          {{ pipe }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>





</div>

<div class="row " style="margin-top: 20px;">
  <div class="col-md-3 mb-2">
    <button [routerLink]="['/pipes-terms-entries-form']" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      اضافة
    </button>
  </div>
</div>












<!-- الكود الخاص بالجدول هنا -->


<div class="table-container mat-elevation-z5">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Columns -->
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>المنطقة</th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>
    <ng-container matColumnDef="lineName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>الخط</th>
      <td mat-cell *matCellDef="let element"> {{element.lineName}} </td>
    </ng-container>

    <ng-container matColumnDef="pipeTermName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>البند</th>
      <td mat-cell *matCellDef="let element"> {{element.pipeTermName}} </td>
    </ng-container>

    <ng-container matColumnDef="pipeTermUnit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>الوحدة</th>
      <td mat-cell *matCellDef="let element"> {{element.pipeTermUnit}} </td>
    </ng-container>

    <ng-container matColumnDef="pipeTermPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>سعر البند</th>
      <td mat-cell *matCellDef="let element"> {{element.pipeTermPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>الكمية</th>
      <td mat-cell *matCellDef="let element"> {{element.amount | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>القيمة</th>
      <td mat-cell *matCellDef="let element"> {{element.price | number:'1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="pipeDivisionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> القسم </th>
      <td mat-cell *matCellDef="let element"> {{element.pipeDivisionName}} </td>
    </ng-container>


    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> من </th>
      <td mat-cell *matCellDef="let element"> {{element.fromS!=null&&element.fromS!=""?element.fromS:formatNumber(element.from)}} </td>
    </ng-container>


    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> الى </th>
      <td mat-cell *matCellDef="let element"> {{element.toS!=null&&element.toS!=""?element.toS:formatNumber(element.to)}} </td>
    </ng-container>

    <ng-container matColumnDef="depth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> العمق </th>
      <td mat-cell *matCellDef="let element">{{element.depth
        }}</td>
    </ng-container>
    <ng-container matColumnDef="height">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> الطول </th>
      <td mat-cell *matCellDef="let element"> {{element.height}} </td>
    </ng-container>



    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ملاحظات </th>
      <td mat-cell *matCellDef="let element"> {{element.note}} </td>
    </ng-container>


    <ng-container matColumnDef="inspectorName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> الاسم </th>
      <td mat-cell *matCellDef="let element"> {{element.inspectorName}} </td>
    </ng-container>


    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> تاريخ الاضافة </th>
      <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'yyyy-MM-dd'}} </td>
    </ng-container>

    <!-- Buttons -->
    <ng-container matColumnDef="photo">
      <th mat-header-cell *matHeaderCellDef>تحميل</th>
      <td mat-cell *matCellDef="let element">
        <a class="btn btn-info" target="_blank" href="{{baseUrl+'/PipeEntryPhotos/'}}{{element.id}}.jpg"
          download="fileName">
          تحميل
        </a>
      </td>
    </ng-container>



    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>حذف</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
          <mat-icon class="mat-18">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>صورة الاستلام</th>
            <td mat-cell *matCellDef="let element">
                <a color="warn" href="{{baseUrl+'/EntryPhotos/'}}{{fg.value.id}}.jpg" download="fileName">
                    <mat-icon class="mat-18">download</mat-icon>
                </a>
            </td>
        </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



  </table>

  <!-- Paginator -->
  <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

  <!-- Loading Indicator -->
  <div *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
  </div>
</div>
