<!-- Loading Indicator -->
<div *ngIf="!pipeTermsEntriesForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>
<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة اعمال جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="pipeTermsEntriesForm" *ngIf="pipeTermsEntriesForm" autocomplete="off" novalidate
                (ngSubmit)="entryCreate(pipeTermsEntriesForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <!-- dropDownLists -->
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>الوحدة</mat-label>
                                <mat-select formControlName="unitId" id="unitId"
                                    (selectionChange)="UnitGetDropDownList($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of UnitsList" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>


                    <div class="row">
                        <!-- Line Here -->
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>الخط</mat-label>
                                <mat-select formControlName="lineId" id="lineId"
                                    (selectionChange)="linesSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of linesList" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row" *ngIf="pipeTermsEntriesForm.value.lineId != 0">
                        <!-- division Here -->
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>القسم</mat-label>
                                <mat-select formControlName="pipeDivisionId" id="pipeDivisionId"
                                    (selectionChange)="pipeDivisionSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of pipeDivisionList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row"
                        *ngIf="pipeTermsEntriesForm.value.lineId != 0 && pipeTermsEntriesForm.value.roomDivisionId !=0">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>البند</mat-label>
                                <mat-select formControlName="pipeTermId" id="pipeTermId"
                                    (selectionChange)="pipeTermsSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of pipeTermsList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Texboxes -->
                    <div *ngIf="classifcation==1">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field >
                                    <mat-label>من</mat-label>
                                    <mat-select formControlName="from" id="quantity"
                                        (selectionChange)="FromStations($event.value)">
                                        <mat-option [value]="0">--اختر--</mat-option>
                                        <mat-option *ngFor="let item of fromStationsList" [value]="item.value">
                                            {{ item.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                              <mat-form-field>
                                <input
                                  matInput
                                  type="text"
                                  placeholder="الى"
                                  formControlName="to"
                                  id="quantity"
                                  readonly
                                  [value]="nextStationLabel || ''"
                                  (input)="onToInputChange(to)"
                                />
                              </mat-form-field>




                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field>
                                    <mat-label> ادخل الطول</mat-label>
                                    <input matInput type="number" placeholder="أدخل الطول" formControlName="height"
                                        id="height" [value]="height" >
                                    <mat-error *ngIf="pipeTermsEntriesForm.get('height').hasError('required')">هذا الحقل
                                        مطلوب</mat-error>
                                </mat-form-field>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field>
                                    <input matInput type="depth" placeholder="العمق" formControlName="depth" id="depth"
                                        [readonly]="depthCalculat == false" [disabled]="depthCalculat == false"
                                        [value]="depth">
                                    <mat-error *ngIf="hasError('to', 'required')">هذا الحقل مطلوب</mat-error>
                                    <!-- <mat-error *ngIf="hasError('to', 'max')">يجب ان لا يزيد عن الكمية القصوى
                    للبند - الكمية المتاحة
                </mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="ملاحظات" formControlName="note" id="note">
                                    <mat-error *ngIf="hasError('note', 'required')">هذا الحقل مطلوب</mat-error>
                                    <mat-error *ngIf="hasError('note', 'maxlength')">الاسم اكثر من 200 حرف
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="col-md-3">
                                    <mat-label>صورة الاستلام</mat-label>
                                    <input type="file" #file accept=".jpg" id="fileUploader"
                                        (change)="selectFile(file.files)">
                                </div>
                                <div class="col-md-4">
                                    <span class="upload" *ngIf="progress > 0">
                                        {{progress}}%
                                    </span>
                                    <span class="upload" *ngIf="message">
                                        {{message}}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>


               <!-- -----------///////////مياه//////////// ------->

               <div *ngIf="classifcation==2">
                <div class="row">
                    <div class="col-md-12">
                       
                        <mat-form-field>
                          <mat-label>من</mat-label>
                          <input
                            matInput
                            type="number"
                            placeholder="أدخل القيمة"
                            formControlName="from"
                            id="quantity2"
                            [value]="from"
                            (input)="onFormInputChange($event.target.value)"
                          />
                          <mat-error *ngIf="hasError('from', 'required')">هذا الحقل مطلوب</mat-error>
                          <mat-error *ngIf="hasError('from', 'max')">
                            يجب أن لا يزيد عن الكمية القصوى للبند - الكمية المتاحة
                          </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                      <mat-form-field>
                        <input
                          matInput
                          type="number"
                          placeholder="الى"
                          formControlName="to"
                          id="quantity"
                          [value]="to"
                          (input)="onToInputChange($event.target.value)"
                        />
                        <mat-error *ngIf="hasError('to', 'required')">هذا الحقل مطلوب</mat-error>
                        <mat-error *ngIf="hasError('to', 'max')">
                          يجب ان لا يزيد عن الكمية القصوى للبند - الكمية المتاحة
                        </mat-error>
                      </mat-form-field>



                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>الطول</mat-label>
                            <input matInput type="number" placeholder="الطول" formControlName="height"
                                id="height" [value]="height" readonly >
                            <mat-error *ngIf="pipeTermsEntriesForm.get('height').hasError('required')">هذا الحقل
                                مطلوب</mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput type="depth" placeholder="العمق" formControlName="depth" id="depth"
                                [readonly]="depthCalculat == false" [disabled]="depthCalculat == false"
                                [value]="depth">
                            <mat-error *ngIf="hasError('to', 'required')">هذا الحقل مطلوب</mat-error>
                            <!-- <mat-error *ngIf="hasError('to', 'max')">يجب ان لا يزيد عن الكمية القصوى
            للبند - الكمية المتاحة
        </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <input matInput type="text" placeholder="ملاحظات" formControlName="note" id="note">
                            <mat-error *ngIf="hasError('note', 'required')">هذا الحقل مطلوب</mat-error>
                            <mat-error *ngIf="hasError('note', 'maxlength')">الاسم اكثر من 200 حرف
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-3">
                            <mat-label>صورة الاستلام</mat-label>
                            <input type="file" #file accept=".jpg" id="fileUploader"
                                (change)="selectFile(file.files)">
                        </div>
                        <div class="col-md-4">
                            <span class="upload" *ngIf="progress > 0">
                                {{progress}}%
                            </span>
                            <span class="upload" *ngIf="message">
                                {{message}}
                            </span>
                        </div>
                    </div>
                </div>

            </div>




                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!pipeTermsEntriesForm.valid">
                                {{ isUpdating == true ? 'تعديل الاعمال' : 'اضافة الاعمال' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>
