import { Unit } from './../../_models/Unit';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.css']
})
export class UnitsListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  projectId: string;
  //Table ==================================================
  public displayedColumns = ['name', 'unitType', 'projectName', 'update', 'delete', 'showPoints'];
  public dataSource = new MatTableDataSource<Unit>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private repo: RepositoryService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.params['id'];
    console.log('projectId is ===>', this.projectId);
    this.unitsGet()
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }


  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.unitsGet();
    }
  }

  //Database =================================================
  public unitsGet = () => {
    this.repo.getData('api/units/unitsGet?projectId=' + this.projectId)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as Unit[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public unitsSearch = (name: string) => {
    this.query = 'api/units/unitsSearch?name=' + name + '&projectId' + this.projectId;
    console.log(this.query);
    if (name != null) {
      this.repo.getData('api/units/unitsSearch?name=' + name + '&projectId' + this.projectId)
        .subscribe(response => {
          this.isLoading = true;
          this.dataSource.data = response as Unit[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الوحدة؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/units/unitDelete?id=' + id).subscribe(response => {
          this.unitsGet();
          this.notificationService.delete('تم حذف الوحدة بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.unitsSearch(value)
  }

  public onShowPointsClick(element: Unit) {
    console.log(element.unitType);
    if (element.unitType == 0) {
      this.router.navigate(['/points/' + element.id]);
    }
    else if (element.unitType == 1) {
      this.router.navigate(['/lines-list/' + element.id]);
    }

  }

}
