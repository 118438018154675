<mat-form-field>
    <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom" (dateChange)="changeFromDate($event.value)"
        placeholder="تاريخ التقديم من">
    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
</mat-form-field>

<form [formGroup]="reportForm" *ngIf="reportForm" autocomplete="off" novalidate (ngSubmit)="onSubmit(reportForm.value)">



    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>نوع التقرير</mat-label>
                <mat-select (selectionChange)="reportSelectionChanged($event.value)" formControlName="reportType"
                    id="reportType">
                    <mat-option *ngFor="let unitType of enumReportType" [value]="unitType.value">
                        {{ unitType.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- تقرير اسبوعي -->
    <div class="row" *ngIf='reportForm.value.reportType == 0'>
        <div class="col-md-12">
            <mat-form-field>
                <input matInput type="text" placeholder="رقم الاسبوع" formControlName="weekNumber" id="weekNumber">
                <mat-error *ngIf="requestForm && hasError('title', 'required')">هذا الحقل مطلوب
                </mat-error>
                <mat-error *ngIf="requestForm && hasError('title', 'maxlength')">الاسم اكثر من 200 حرف
                </mat-error>
            </mat-form-field>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>اسم المشروع</mat-label>
                <mat-select formControlName="projectId" id="projectId">
                    <mat-option *ngFor="let project of projectsDropDownList" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>



    <div class="row" *ngIf='reportForm.value.reportType == 0'>
        <!-- رفع الصورة -->
        <div class="col-md-12">
            <div class="col-md-3">
                <mat-label *ngIf='reportForm.value.reportType == 0'>صورة Labors</mat-label>
                <input type="file" #file accept=".jpg" id="fileUploader" (change)="selectFirstPicture(file.files)">
            </div>
            <div class="col-md-4">
                <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                </span>
                <span class="upload" *ngIf="message">
                    {{message}}
                </span>
            </div>
        </div>
    </div>



    <div class="row" *ngIf='reportForm.value.reportType ==0'>
        <!-- رفع الصورة -->
        <div class="col-md-12">
            <div class="col-md-3">
                <mat-label *ngIf='reportForm.value.reportType == 0'>صورة Equipment</mat-label>
                <input type="file" #file2 accept=".jpg" id="fileUploader2" (change)="selectSecondPicture(file2.files)">
            </div>
            <div class="col-md-4">
                <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                </span>
                <span class="upload" *ngIf="message">
                    {{message}}
                </span>
            </div>
        </div>
    </div>

    
    <!-- ############################################الصور اللانهائية ####################################################### -->
    <div  *ngIf='reportForm.value.reportType == 0'>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>1</mat-label>
                    <input type="file" #file3 accept=".jpg" id="fileUploader11" (change)="selectPic1(file3.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>2</mat-label>

                    <input type="file" #file4 accept=".jpg" id="fileUploader22" (change)="selectPic2(file4.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>3</mat-label>

                    <input type="file" #file5 accept=".jpg" id="fileUploader33" (change)="selectPic3(file5.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>4</mat-label>

                    <input type="file" #file6 accept=".jpg" id="fileUploader44" (change)="selectPic4(file6.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>5</mat-label>

                    <input type="file" #file7 accept=".jpg" id="fileUploader55" (change)="selectPic5(file7.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>6</mat-label>

                    <input type="file" #file8 accept=".jpg" id="fileUploader66" (change)="selectPic6(file8.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>7</mat-label>

                    <input type="file" #file9 accept=".jpg" id="fileUploader77" (change)="selectPic7(file9.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- رفع الصورة -->
            <div class="col-md-12">
                <div class="col-md-3">
                    <mat-label>8</mat-label>

                    <input type="file" #file10 accept=".jpg" id="fileUploader88" (change)="selectPic8(file10.files)">
                </div>
                <div class="col-md-4">
                    <span class="upload" *ngIf="progress > 0">
                        {{progress}}%
                    </span>
                    <span class="upload" *ngIf="message">
                        {{message}}
                    </span>
                </div>
            </div>
        </div>
    </div>
   



    <button mat-raised-button color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>

</form>



