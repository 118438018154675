import { environment } from './../../environments/environment.prod';
import { RequestAttachment } from './../_models/RequestAttachment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../services/notifications.service';
import { RepositoryService } from '../services/repository.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-request-attachments',
  templateUrl: './request-attachments.component.html',
  styleUrls: ['./request-attachments.component.css']
})
export class RequestAttachmentsComponent implements OnInit {
  requestId: string;
  requestAttachments: RequestAttachment[];
  isLoading: boolean;
  baseUrl: string = environment.urlAddress;

  usertype = localStorage.getItem('userType');
  //Table ==================================================
  public displayedColumns = [ 'url', 'download'];
  public dataSource = new MatTableDataSource<RequestAttachment>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private confirmDialogService: DialogService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.requestId = this.activeRoute.snapshot.params['id'];
    console.log(this.requestId);
    this.requestAttachmentsGet();
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الريكوست');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/requests/RequestAttachmentDelete?id=' + id).subscribe(response => {
          this.requestAttachmentsGet();
          this.notificationsService.delete('تم حذف الريكوست بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }


  public requestAttachmentsGet = () => {
    this.isLoading = true;
    this.repo.getData('api/requests/RequestAttachmentsGet?requestId=' + this.requestId)
      .subscribe(response => {
        this.dataSource.data = response as RequestAttachment[]
        this.isLoading = false;
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
