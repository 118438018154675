import { PipeDivision } from './../../_models/PipeDivision';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pipes-divisions-form',
  templateUrl: './pipes-divisions-form.component.html',
  styleUrls: ['./pipes-divisions-form.component.css']
})
export class PipesDivisionsFormComponent implements OnInit {

  //Vars ====================================
  pipesDivisionsForm: FormGroup;
  pipesDivision: PipeDivision;
  lineId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.createpipesDivisionssForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.lineId = this.activeRoute.snapshot.params['lineId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.pipesDivisionsGetById(this.id);
    }
  }


  public createpipesDivisionssForm() {
    this.pipesDivisionsForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
  }

  public onSubmit = (pipesDivisionsFormValue) => {
    if (this.pipesDivisionsForm.valid) {
      if (this.isUpdating) {
        this.pipesDivisionsUpdate(pipesDivisionsFormValue);
      }
      else {
        this.pipesDivisionsCreate(pipesDivisionsFormValue);
      }
    }
  }

  private pipesDivisionsCreate = (pipesDivisionsFormValue) => {
    let pipesDivisions: PipeDivision = {
      name: pipesDivisionsFormValue.name,
      lineId: this.lineId,
    }
    console.log(pipesDivisions);
    this.repo.create('api/Pipedivision/PipeDivisionCreate', pipesDivisions)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public pipesDivisionsGetById = (id: string) => {
    this.repo.getData('api/Pipedivision/PipeDivisionGetById?id=' + id).subscribe(response => {
      this.pipesDivision = response as PipeDivision
      // this.pipesDivisionsForm.patchValue(this.pipesDivisions);
      this.pipesDivisionsForm.get('id').setValue(this.pipesDivision.id);
      this.pipesDivisionsForm.get('name').setValue(this.pipesDivision.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pipesDivisionsUpdate = (pipesDivisionsFormValue) => {
    let pipesDivisions: PipeDivision = {
      id: pipesDivisionsFormValue.id,
      name: pipesDivisionsFormValue.name,
      lineId: this.lineId,
    }
    console.log(pipesDivisions);
    this.repo.update('api/Pipedivision/PipeDivisionUpdate', pipesDivisions)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pipesDivisionsForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/pipes-divisions-list/' + this.lineId])
  }

}
