import { finalize, switchMap, map, concatMap } from 'rxjs/operators';
import { ProjectDropDownList } from './../../_models/ProjectDropDownList';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from 'src/app/services/repository.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { concat } from 'rxjs';

@Component({
  selector: 'app-weekly-report-form',
  templateUrl: './weekly-report-form.component.html',
  styleUrls: ['./weekly-report-form.component.css']
})
export class WeeklyReportFormComponent implements OnInit {

  enumReportType = Constants.enumReportType;
  reportForm: FormGroup;
  projectsDropDownList: ProjectDropDownList[] = [];

  dateSearchFrom: string;
  dateSearchTo: Date;

  dateFrom: string;
  dateTo: string;

  selectedReport: number;


  public progress: number;

  selectedFiles: FileList;
  formData: FormData;
  fileToUpload: File;

  selectedFiles2: FileList;
  formData2: FormData;
  fileToUpload2: File;

  message: string;

  baseUrl = environment.urlAddress;




  constructor(public datePipe: DatePipe, private repo: RepositoryService, private http: HttpClient,) { }

  ngOnInit(): void {
    this.projectsGetDropDownList();
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()
    this.dateSearchFrom = this.dateFrom;
  }


  public search = () => {

  }

  public createRequestsForm() {
    this.reportForm = new FormGroup({
      weekNumber: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      reportType: new FormControl(this.enumReportType[1].value),
      projectId: new FormControl(this.projectsDropDownList[0].id),
    });
  }



  public onSubmit = (formValues) => {
    console.log(formValues);
    console.log(formValues.reportType)

    let from = moment(this.dateSearchFrom).format('YYYY-MM-DD');
    let to = moment(this.dateSearchFrom).add(7, 'days').format('YYYY-MM-DD');


    //Weekly #########################
    if (formValues.reportType == 0) {
      console.log(this.formData2);
      console.log(this.formData);
      if (this.reportForm.valid) {
        console.log('here1')
        console.log('Weekly')
        this.uploadFirstPicture('Labors', 'ReportPhotos').subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response && !this.selectedFiles) {

            console.log('here1')
            try {
              this.uploadSecondPicture('ManPower', 'ReportPhotos').subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                  this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response && !this.selectedFiles2) {


                  this.uploadTonsOfPics('weekly1', 'ReportPhotos', this.formData11).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress)
                      this.progress = Math.round(100 * event.loaded / event.total);
                    else if (event.type === HttpEventType.Response && !this.selectedFiles2) {


                      this.uploadTonsOfPics('weekly2', 'ReportPhotos', this.formData22).subscribe(event => {
                        if (event.type === HttpEventType.UploadProgress)
                          this.progress = Math.round(100 * event.loaded / event.total);
                        else if (event.type === HttpEventType.Response && !this.selectedFiles2) {



                          this.uploadTonsOfPics('weekly3', 'ReportPhotos', this.formData33).subscribe(event => {
                            if (event.type === HttpEventType.UploadProgress)
                              this.progress = Math.round(100 * event.loaded / event.total);
                            else if (event.type === HttpEventType.Response && !this.selectedFiles2) {



                              this.uploadTonsOfPics('weekly4', 'ReportPhotos', this.formData44).subscribe(event => {
                                if (event.type === HttpEventType.UploadProgress)
                                  this.progress = Math.round(100 * event.loaded / event.total);
                                else if (event.type === HttpEventType.Response && !this.selectedFiles2) {




                                  this.uploadTonsOfPics('weekly5', 'ReportPhotos', this.formData55).subscribe(event => {
                                    if (event.type === HttpEventType.UploadProgress)
                                      this.progress = Math.round(100 * event.loaded / event.total);
                                    else if (event.type === HttpEventType.Response && !this.selectedFiles2) {




                                      this.uploadTonsOfPics('weekly6', 'ReportPhotos', this.formData66).subscribe(event => {
                                        if (event.type === HttpEventType.UploadProgress)
                                          this.progress = Math.round(100 * event.loaded / event.total);
                                        else if (event.type === HttpEventType.Response && !this.selectedFiles2) {




                                          this.uploadTonsOfPics('weekly7', 'ReportPhotos', this.formData77).subscribe(event => {
                                            if (event.type === HttpEventType.UploadProgress)
                                              this.progress = Math.round(100 * event.loaded / event.total);
                                            else if (event.type === HttpEventType.Response && !this.selectedFiles2) {




                                              this.uploadTonsOfPics('weekly8', 'ReportPhotos', this.formData88).subscribe(event => {
                                                if (event.type === HttpEventType.UploadProgress)
                                                  this.progress = Math.round(100 * event.loaded / event.total);
                                                else if (event.type === HttpEventType.Response && !this.selectedFiles2) {



                                                  let url: string = `http://adlink2019-001-site37.etempurl.com/index.aspx?userId=${formValues.projectId}
                                                  &weekNumber=${String(formValues.weekNumber).trim()}&fromDate=${from}&toDate=${to}`;
                                                  window.location.href = url.split(' ').join('')
                                                }






                                              });
                                            }






                                          });
                                        }






                                      });
                                    }






                                  });
                                }






                              });
                            }






                          });
                        }






                      });
                    }






                  });




                }
              });


            } catch (error) {
              let url: string = `http://adlink2019-001-site37.etempurl.com/index.aspx?userId=${formValues.projectId}
            &weekNumber=${String(formValues.weekNumber).trim()}&fromDate=${from}&toDate=${to}`;
              window.location.href = url.split(' ').join('')
            }

          }
        });



      }

    }

    //ShowReport



    //dashboard #########################
    else if (formValues.reportType == 1) {
      console.log('indexDash')
      //console.log(this.formData.get)
      //  console.log(this.formData2.get)
      try {
        this.uploadFirstPicture('daily1', 'ReportPhotos').subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response && !this.selectedFiles) {
            console.log('here1')

            //ShowReport
            let url: string = `http://adlink2019-001-site37.etempurl.com/indexdash.aspx?
            userId=${formValues.projectId}&fromDate=${from}&toDate=${to}`

            window.location.href = url.split(' ').join('');


          }
        });;
      } catch (error) {
        //ShowReport
        let url: string = `http://adlink2019-001-site37.etempurl.com/indexdash.aspx?
        userId=${formValues.projectId}&fromDate=${from}&toDate=${to}`

        window.location.href = url.split(' ').join('');
      }


      // }







      //http://localhost:1198/index.aspx?userId=8&weekNumber=7&fromDate=2021-05-15%2000:00:00&toDate=2021-05-23%2023:59:59

    }
  }

  addDays(days: number): Date {
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
    console.log(this.dateSearchFrom);
  }



  public projectsGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList')
      .subscribe(response => {
        this.projectsDropDownList = response as ProjectDropDownList[];
        this.createRequestsForm()
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }




  public selectFirstPicture = (files) => {
    console.log('here1', files[0], files[1]);

    if (files.length === 0) {
      return;
    }
    this.fileToUpload = <File>files[0];
    this.formData = new FormData();
    this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.formData.forEach(element => {
      console.log(element)
    })
  }


  public selectSecondPicture = (files: File[]) => {
    console.log('here2', files[0], files[1]);

    if (files.length === 0) {
      return;
    }
    this.fileToUpload2 = <File>files[0];
    this.formData2 = new FormData();
    this.formData2.append('file2', this.fileToUpload2, this.fileToUpload2.name);
    this.formData2.forEach(element => {
      console.log(element)
    })
  }

  //######################الصور اللانهائية

  formData11: FormData;
  fileToUpload11: File;

  public selectPic1 = (files) => {
    console.log('here1')
    if (files.length === 0) {
      return;
    }
    this.fileToUpload11 = <File>files[0];
    this.formData11 = new FormData();
    this.formData11.append('file', this.fileToUpload11, this.fileToUpload11.name);
  }

  formData22: FormData;
  fileToUpload22: File;
  public selectPic2 = (files) => {
    console.log('here2')

    if (files.length === 0) {
      return;
    }
    this.fileToUpload22 = <File>files[0];
    this.formData22 = new FormData();
    this.formData22.append('file', this.fileToUpload22, this.fileToUpload22.name);
  }

  formData33: FormData;
  fileToUpload33: File;
  public selectPic3 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload33 = <File>files[0];
    this.formData33 = new FormData();
    this.formData33.append('file', this.fileToUpload33, this.fileToUpload33.name);
  }

  formData44: FormData;
  fileToUpload44: File;
  public selectPic4 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload44 = <File>files[0];
    this.formData44 = new FormData();
    this.formData44.append('file', this.fileToUpload44, this.fileToUpload44.name);
  }

  formData55: FormData;
  fileToUpload55: File;
  public selectPic5 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload55 = <File>files[0];
    this.formData55 = new FormData();
    this.formData55.append('file', this.fileToUpload55, this.fileToUpload55.name);
  }

  formData66: FormData;
  fileToUpload66: File;
  public selectPic6 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload66 = <File>files[0];
    this.formData66 = new FormData();
    this.formData66.append('file', this.fileToUpload66, this.fileToUpload66.name);
  }

  formData77: FormData;
  fileToUpload77: File;
  public selectPic7 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload77 = <File>files[0];
    this.formData77 = new FormData();
    this.formData77.append('file', this.fileToUpload77, this.fileToUpload77.name);
  }

  formData88: FormData;
  fileToUpload88: File;
  public selectPic8 = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload88 = <File>files[0];
    this.formData88 = new FormData();
    this.formData88.append('file', this.fileToUpload88, this.fileToUpload88.name);
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  reportSelectionChanged(event: any) {
    this.selectedReport = event;
    console.log(this.selectedReport);
  }

  public uploadFirstPicture(fileName: string, folderName: string) {
    return this.http.post(this.baseUrl + '/api/upload/uploadReports?fileName=' + fileName + '&folderName=' + folderName
      , this.formData, { reportProgress: true, observe: 'events' })

  }

  public uploadSecondPicture(fileName: string, folderName: string) {
    return this.http.post(this.baseUrl + '/api/upload/uploadReports?fileName=' + fileName + '&folderName=' + folderName
      , this.formData2, { reportProgress: true, observe: 'events' })

  }






  public uploadTonsOfPics(fileName: string, folderName: string, formData: FormData) {
    //console.log(formData.get)
    return this.http.post(this.baseUrl + '/api/upload/uploadReports?fileName=' + fileName + '&folderName=' + folderName
      , formData, { reportProgress: true, observe: 'events' })

  }


}
