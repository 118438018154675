import { PipeTermsEntries } from './../../_models/PipeTermsEntries';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pipe-terms-entries-list',
  templateUrl: './pipe-terms-entries-list.component.html',
  styleUrls: ['./pipe-terms-entries-list.component.css']
})
export class PipeTermsEntriesListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;

  dateFrom: string;
  dateTo: string;
  dateSearchFrom: string;
  dateSearchTo: string;
  allData: any;
  baseUrl = environment.urlAddress;

  //Table ==================================================
  public displayedColumns =
    [
      'location',
      'lineName',
      'pipeDivisionName',
      'pipeTermName',
      'from',
      'to',
      'height',
      'depth',

      'pipeTermPrice',
      'pipeTermUnit',
      'amount',
      'price',
      'creationDate',
      'note',
      'inspectorName',
      'photo', 'delete'
    ];

    uniqueLocations: any;
  uniqueDivisions: any;
  uniqueLines: any;
  uniquepipeTermName: any;
  selectedpipeTermName: any;
  selectedDivision: any;
  selectedLocation: any;
  selectedLines: any;
  public dataSource = new MatTableDataSource<PipeTermsEntries>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    public datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();

    //this.pipesTermsEntriesGet();
    this.loadData();
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }


  public formatNumber(input: number): string {
    if (input < 1000) {
      // For numbers less than 1000
      return `0+${input.toString().padStart(3, '0')}`;
    } else {
      // For numbers 1000 or greater
      const thousands = Math.floor(input / 1000);
      const remainder = input % 1000;
      return `${thousands}+${remainder.toString().padStart(3, '0')}`;
    }
  }
  //Database =================================================
  // public pipesTermsEntriesGet = () => {
  //   this.isLoading = true;
  //   this.repo.getData('api/pipeTermEntries/pipeTermEntrieGet?dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo+ '&userType=' + localStorage.getItem('userType')+ '&inspectorId=' + localStorage.getItem('userId'))
  //     .subscribe(response => {
  //       this.dataSource.data = response as PipeTermsEntries[];
  //       console.log(response);
  //       this.isLoading = false;
  //     }, (error) => {
  //       console.log(error);
  //     })
  // }

  private loadData(): void {
    this.repo.getData('api/pipeTermEntries/pipeTermEntrieGet?dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo + '&userType=' + localStorage.getItem('userType') + '&inspectorId=' + localStorage.getItem('userId'))
    .subscribe(response => {
       console.log(response);
      this.allData = response;
      this.dataSource.data = this.allData;
      this.isLoading = false;

      // Get unique values for dropdowns
      this.uniqueDivisions = Array.from(new Set(this.allData.map(item => item.pipeDivisionName)));
      this.uniqueLocations = Array.from(new Set(this.allData.map(item => item.location)));

      this.uniqueLines = Array.from(new Set(this.allData.map(item => item.lineName)));
      this.uniquepipeTermName = Array.from(new Set(this.allData.map(item => item.pipeTermName)));
    });
  }
  // applyFilter(): void {
  //   this.dataSource.data = this.allData.filter(item => {
  //     const heightQuantity=item.to- item.from;
  //     const divisionMatch = !this.selectedDivision || item.pipeDivisionName === this.selectedDivision;
  //     const LocationsMatch = !this.selectedLocation || item.location === this.selectedLocation;
  //     const lineMatch = !this.selectedLines || item.lineName === this.selectedLines;
  //     const pipeMatch = !this.selectedpipeTermName || heightQuantity === this.selectedpipeTermName;
  //     return divisionMatch && LocationsMatch && lineMatch && pipeMatch;
  //   });
  // }
  filterByUnit(unitName: string) {
    if (unitName) {
      this.dataSource.data = this.allData.filter(item => item.location === unitName);
    } else {
      // Reset the data or fetch again if no filter is applied
      this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
  }
  filterByLine(lineName: string) {
    if (lineName) {
      this.dataSource.data = this.allData.filter(item => item.lineName === lineName);
    } else {
      // Reset the data or fetch again if no filter is applied
      this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
  }

  filterByPipe(PipeName: string) {
    if (PipeName) {
      this.dataSource.data = this.allData.filter(item => item.pipeTermName === PipeName);
    } else {
      // Reset the data or fetch again if no filter is applied
      this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
  }
  filterBydivision(divisionName: string) {
    if (divisionName) {
      this.dataSource.data = this.allData.filter(item => item.pipeDivisionName === divisionName);
    } else {
      this.dataSource.data = this.allData;
    }
  }

  public pipesTermEntriesSearch = (note: string) => {
    this.isLoading = true;
    if (note != null) {
      this.repo.getData('api/pipeTermEntries/pipeTermEntrieSearch?note=' + note + '&dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo + '&userType=' + localStorage.getItem('userType') + '&inspectorId=' + localStorage.getItem('userId'))
        .subscribe(response => {
       //  this.dataSource.data = response as PipeTermsEntries[];
         this.allData = response;
        this.dataSource.data = this.allData;
        // this.isLoading = false;

        // Get unique values for dropdowns
        this.uniqueDivisions = Array.from(new Set(this.allData.map(item => item.pipeDivisionName)));
        this.uniqueLocations = Array.from(new Set(this.allData.map(item => item.location)));

        this.uniqueLines = Array.from(new Set(this.allData.map(item => item.lineName)));
        this.uniquepipeTermName = Array.from(new Set(this.allData.map(item => item.pipeTermName)));
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الاعمال؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/pipeTermEntries/pipeTermEntrieDelete?id=' + id).subscribe(response => {
          // this.pipesTermsEntriesGet();
          this.loadData();
          this.notificationsService.delete('تم حذف الاعمال بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });
  }



  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    if (value == null) { value = ''; }
    this.pipesTermEntriesSearch(value)
  }

  public clearSearch = (value: string) => {
    //this.pipesTermsEntriesGet();
    this.loadData();
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
  }

}
