import { PipeDivisionDropDownList } from './../../_models/PipeDivisionDropDownList';
import { PipeTermsEntries } from './../../_models/PipeTermsEntries';
import { PipeTermDropDownList } from './../../_models/PipeTermDropDownList';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationsService } from 'src/app/services/notifications.service';
import { LineDropDownList } from 'src/app/_models/LineDropDownList';
import { RoomDivisionDropDownList } from 'src/app/_models/RoomDivisionDropDownList';

@Component({
  selector: 'app-pipe-terms-entries-form',
  templateUrl: './pipe-terms-entries-form.component.html',
  styleUrls: ['./pipe-terms-entries-form.component.css']
})
export class PipeTermsEntriesFormComponent implements OnInit {

  //Vars ====================================
  pipeTermsEntriesForm: FormGroup;
  selectedUnit:any;
  linesList: any;
  maxStation:any
  pipeTermsdropDownList: any;
  pipeDivisionList: any;
  pipeTermsList:any;
  UnitsList:any;
  fromStationsList:any;
  maxQuantityFromTerms: number = 0;
  availableQuantity: number = 0;
  termUnit: string;
  formData: FormData;
  selectedFiles: FileList;
  fileToUpload: File;
  lineId:any;
  to:any;
  from:any;
  height:any
  pipeTermId:any;
  classifcation:any;
  pipeDivisionId:any;
  baseUrl = environment.urlAddress;
  PipeTermsEntrie: PipeTermsEntries;
  isUpdating: boolean = false;
  id: string;
  public progress: number;
  entryId: string;
  unitId:any;
  isLoading: boolean = false;
  inspectorId: string = localStorage.getItem('userId')
  quantityMaxAllowedValue: number = 0;
  selectedPipe:any;
  depth:any;
  depthCalculat:any;
  searchControl = new FormControl('');
  nextStationLabel: any;
  selectedLine:any;
  filteredLinesList: any[] = [];
  constructor(private router: Router,
    private repo: RepositoryService,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.params['id'];
    this.loadUnitsData();

  }
  ///===================================================================================================
  loadUnitsData() {
    const inspectorId = localStorage.getItem('userId');
    this.repo.getData(`api/roomDropDownList/unitsForPipesTermEntries?inspectorId=${inspectorId}`).subscribe(
      (res: any) => {
        this.UnitsList = res;
        this.createPipesTermsEntriesForm();
        console.log(this.UnitsList);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  UnitGetDropDownList(selectedValue: number) {
    this.unitId = selectedValue;

    this.selectedUnit = this.UnitsList.find(unit => unit.id === selectedValue);
    this.classifcation= this.selectedUnit.classification;

    console.log(this.classifcation);
    console.log('Selected Unit:', this.selectedUnit);

    this.repo.getData(`api/roomDropDownList/lineForPipesTermsEntriesByUnit?unitId=${selectedValue}`).subscribe(
      (res: any) => {
        this.linesList = res;

        console.log(res);
      },
      (error) => {
        console.error(error);
      }
    );

  }

  linesSelectionChanged(selectedValue: number) {
    this.lineId = selectedValue;
    this.selectedLine = this.linesList.find(line => line.id === selectedValue);
    this.maxStation= this.selectedLine.maxStation;
    console.log( this.maxStation);
    this.repo.getData(`api/Pipedivision/PipeDivisionDropDownList?lineId=${selectedValue}`).subscribe(
      (res: any) => {
        this.pipeDivisionList = res;
      },
      (error) => {
        console.error(error);
      }
    );
    this.repo.getData(`api/roomDropDownList/stationsByLine?lineId=${selectedValue}`).subscribe(
      (res: any) => {
        this.fromStationsList = res;
        console.log(res)
      },
      (error) => {
        console.error(error);
      }
    );
  }

  FromStations(selectedValue: number) {
    this.from = selectedValue;

    const selectedIndex = this.fromStationsList.findIndex(item => item.value === selectedValue);
    if (selectedIndex !== -1 && selectedIndex < this.fromStationsList.length - 1) {
        const nextStation = this.fromStationsList[selectedIndex + 1];
        this.to = nextStation.value; // إرسال القيمة
        this.nextStationLabel = nextStation.label; // عرض النص
    } else {
        this.to = null; // إعادة التعيين إذا لم يكن هناك عنصر تالي
        this.nextStationLabel = null;
    }

    // تحديث النموذج
    this.pipeTermsEntriesForm.get('to')?.setValue(this.to);

    // استدعاء العمليات الإضافية إذا لزم الأمر
    this.calculateHeight();
}



  calculateHeight() {
    if (this.classifcation === 2 && this.from != null && this.to != null) {
      this.height = Math.abs(this.to - this.from);
//this.pipeTermsEntriesForm.get('height').setValue(this.height);
    }
  }

  onToInputChange(value: number) {
    this.to = value;
    this.pipeTermsEntriesForm.get('to')?.setValue(value);

    if (this.classifcation == 2) {
        this.calculateHeight();

    }

}

  pipeTermsSelectionChanged(selectedValue: number) {
    this.pipeTermId = selectedValue;
    this.selectedPipe = this.pipeTermsList.find(pipe => pipe.id === selectedValue);
    this.depthCalculat= this.selectedPipe.depthCalculated;
    console.log(this.depthCalculat);
    this.depth=this.selectedPipe.depth;
    console.log( this.depth);
  }

 pipeDivisionSelectionChanged(selectedValue: number) {
  this.pipeDivisionId = selectedValue;
  this.repo.getData(`api/PipeTerms/PipeTermsGetDropDownList?pipeDivisionId=${selectedValue}`).subscribe(
    (res: any) => {
      this.pipeTermsList = res;
    },
    (error) => {
      console.error(error);
    }
  );
}

onFormInputChange(value: number): void {
  this.from = value;

  this.pipeTermsEntriesForm.get('from')?.setValue(value);

}





  public createPipesTermsEntriesForm() {
    console.log('PipeTermsEntriesForm');
    this.pipeTermsEntriesForm = new FormGroup({
      unitId: new FormControl(0, [Validators.min(1)]),
      lineId: new FormControl(0, [Validators.min(1)]),
      pipeDivisionId: new FormControl(0, [Validators.min(1)]),
      id: new FormControl(null),
      pipeTermId: new FormControl(0, [Validators.min(1)]),
      note: new FormControl(''),
      from: new FormControl(null, [Validators.min(0)]),
      to: new FormControl(null, [ Validators.min(0)]),
      height: new FormControl(null, [ Validators.min(0)]),
      depth: new FormControl(null,[Validators.min(0)]),
      //height: new FormControl([Validators.min(0)]),
    });
  }

  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload = <File>files[0];
    this.formData = new FormData();
   // this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
   this.formData.append('file', this.fileToUpload, this.fileToUpload.name.replace('jpeg','jpg').replace('png','jpg'));
  }

  public entryCreate(entryFormValues: any): void {
    if (this.fileToUpload == undefined) {
      this.notificationsService.delete('فضلا ادخل صورة الاستلام');
    }
    if (this.pipeTermsEntriesForm.invalid) {
      this.notificationsService.delete('يرجى ملء الحقول المطلوبة بشكل صحيح');
      return;
    }

    if (entryFormValues.from > entryFormValues.to) {
      this.notificationsService.delete('يجب ان يكون (من) اقل من (الى)');
      return;
    }
    if(entryFormValues.from > this.maxStation || entryFormValues.to > this.maxStation){

      this.notificationsService.delete( `(${this.maxStation}) يجب ان يكون (من) لا يتعدى الحد الأقصى المسموح به و هو `);
      return;
    }

    const term: PipeTermsEntries = {
      inspectorId: this.inspectorId,
      pipeTermId: entryFormValues.pipeTermId,
      note: entryFormValues.note,
      from: entryFormValues.from,
      to: entryFormValues.to,
      height: entryFormValues.height,
      depth: entryFormValues.depth,
    };
    if(!this.depthCalculat){
      term.depth = this.selectedPipe.depth;
    }
    if (term.height<0) {
      this.notificationsService.delete('قيمة الطول يجب أن تكون أكبر من الصفر');
      return;
    }
    if (term.depth<0) {
      this.notificationsService.delete('قيمة العمق يجب أن تكون أكبر من الصفر');
      return;
    }
    this.repo
      .create('api/pipeTermEntries/PipeTermEntrieCreate', term)
      .subscribe(
        (response: PipeTermsEntries) => {
          if (response.note === 'invalid range') {
            this.notificationsService.delete('الرينج موجود مسبقا');
          } else if (response.note === 'invalid dependancy') {
            this.notificationsService.delete(
              'فضلا ادخل الاعمال الخاصة بالبند السابق اولا'
            );
          } else {
            this.entryId = response.id;
            this.uploadFile();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }




  //#region events



  public uploadFile() {
    this.http.post(this.baseUrl + '/api/PipeTermEntries/EntryUploadPhoto?entryId=' + this.entryId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          // this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }


  public onCancel = () => {
    this.router.navigate(['/pipes-terms-entries-list'])
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.pipeTermsEntriesForm.controls[controlName].hasError(errorName);
  }
}
