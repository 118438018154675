<div class="gradient-Header">
    <div class="gradient-child">
        <h3>المخطط الزمني</h3>
    </div>
</div>



<div fxLayout="row" fxLayoutAlign="space-around center">
    <mat-form-field>
        <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text" placeholder="بحث">
    </mat-form-field>


    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
                (dateChange)="changeFromDate($event.value)" placeholder="من">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo"
                (dateChange)="changeToDate($event.value)" placeholder="الى">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>


    <button mat-raised-button (click)="search(searchText)" color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>

    <button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
        <mat-icon>search</mat-icon>
        عرض الكل
    </button>
</div>


<div class="row">
    <div class="col-md-3 mb-2">
        <button (click)="onAddClick()" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>

    <div class="col-md-3 mb-2">
        <button [routerLink]="['/projects']" mat-raised-button color="secondary">
            <mat-icon>back</mat-icon>
            عودة للمشاريع
        </button>
    </div>
</div>

<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->
        <ng-container matColumnDef="pointName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم النقطه او الخط </th>
            <td mat-cell *matCellDef="let element"> {{element.pointName}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="lineName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم الخط </th>
            <td mat-cell *matCellDef="let element"> {{element.lineName}} </td>
        </ng-container> -->


        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> تاريخ </th>
            <td mat-cell *matCellDef="let element"> {{element.date |date:'yyyy-MM-dd'}} </td>
        </ng-container>

        <ng-container matColumnDef="accumulatedAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> المبلغ التراكمي </th>
            <td mat-cell *matCellDef="let element"> {{element.accumulatedAmount }} </td>
        </ng-container>

        <ng-container matColumnDef="accumulatedPercentage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> النسبة التراكمية </th>
            <td mat-cell *matCellDef="let element"> {{element.accumulatedPercentage | percent : '1.2-2'}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>