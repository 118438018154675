<mat-card style="margin-bottom: 30px;" class="mat-elevation-z10">
    <div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-2">

                <mat-form-field>
                    <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text"
                        placeholder="بحث">
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field *ngIf="usertype=='0' ||usertype=='5'">
                    <mat-label>اسم المشروع</mat-label>
                    <mat-select (selectionChange)="changeProject($event.value)">
                        <mat-option *ngFor="let project of projectsList" [value]="project.id">
                            {{project.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>نوع الطلب</mat-label>
                    <mat-select (selectionChange)="changeType($event.value)">
                        <mat-option *ngFor="let requestType of requestTypesList" [value]="requestType.id">
                            {{requestType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
                        (dateChange)="changeFromDate($event.value)" placeholder="تاريخ التقديم من">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo"
                        (dateChange)="changeToDate($event.value)" placeholder="تاريخ التقديم الى">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="col-md-2">

                <button mat-raised-button (click)="search(searchText)" color="primary">
                    <mat-icon>search</mat-icon>
                    بحث
                </button>
            </div>
            <div class="col-md-2">

                <button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
                    <mat-icon>search</mat-icon>
                    عرض الكل
                </button>
            </div>

            <!-- <button (click)="exportToExcel()">Export The Report</button> -->
        </div>

        <!-- <div class="row" *ngIf="usertype=='0'||usertype=='2'||usertype=='5'">
            <div class="col-md-12">
                <mat-checkbox (change)="getAllUnResponsedRequests($event.checked)" [(ngModel)]="unResponsedchecked"
                    [checked]="unResponsedchecked" color="primary">عرض الطلبات الغير مردود
                    عليها</mat-checkbox>
            </div>
        </div> -->
    </div>

</mat-card>




<mat-card style="margin-bottom: 20px;">
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>

    <div style="direction: ltr;">
        <div class="col-md-12">
            <!-- chart -->
            <div id="ProjectManagerResponseChart"
                style="height: 370px; width: 100%; margin-left:auto;margin-right:auto;text-align: start;">
            </div>
        </div>
    </div>


    <!-- Loading Indicator -->

</mat-card>


<mat-card style="margin-bottom: 20px;">
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>

    <div style="direction: ltr;">
        <div class="col-md-12">
            <!-- chart -->
            <div id="ProjectManagerResponseTypeChart"
                style="height: 370px; width: 100%; margin-left:auto;margin-right:auto;text-align: start;">
            </div>
        </div>
    </div>




</mat-card>


<mat-card style="margin-bottom: 20px;">
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>

    <div style="direction: ltr;">
        <div class="col-md-12">
            <!-- chart -->
            <div id="TypesPercentageChart"
                style="height: 370px; width: 100%; margin-left:auto;margin-right:auto;text-align: start;">
            </div>
        </div>
    </div>




</mat-card>


<mat-card style="margin-bottom: 20px;">
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>

    <div style="direction: ltr;">
        <div class="col-md-12">
            <!-- chart -->
            <div id="PointsChart"
                style="height: 370px; width: 100%; margin-left:auto;margin-right:auto;text-align: start;">
            </div>
        </div>
    </div>




</mat-card>