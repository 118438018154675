import { AuthService } from './../services/auth.service';
import { User } from './../_models/User';
import { Constants } from 'src/app/Constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../services/notifications.service';
import { RepositoryService } from '../services/repository.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() error: string | null;
  @Output() submitEM = new EventEmitter();


  form: FormGroup;
  isLoading: boolean;
  isLoggedIn = Constants.isLoggedIn;


  constructor(private router: Router,
    private repo: RepositoryService,
    public notificationsService: NotificationsService,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.isLoggedIn = false;
    this.logout();
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  logout() {
    this.removeFromLocalStorage();
    this.router.navigate(['/login']);
    this.authService.setLoggedIn(false);
  }
  removeFromLocalStorage = () => {
    localStorage.clear();
  }
  login(loginformValues) {
    console.log("loginScreen: " + this.getFromLocalStorage());
    let username: string = loginformValues.username;
    let password: string = loginformValues.password;
    this.isLoading = true;
    this.repo.getData('api/users/userLogin?username=' + username + '&password=' + password).subscribe(response => {
      let user: User = response as User;
      // console.log(sessionStorage.getItem('returnUrl'));
      this.authService.login(user);

      this.isLoading = false;
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }



  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }





  getFromLocalStorage(): string {
    return localStorage.getItem('userType');
  }

}
