import { LineDropDownList } from 'src/app/_models/LineDropDownList';
import { PointDropDownList } from './../../_models/PointDropDownList';
import { TimeFrame } from './../../_models/TimeFrame';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/Constants';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-time-frame-form',
  templateUrl: './time-frame-form.component.html',
  styleUrls: ['./time-frame-form.component.css']
})
export class TimeFrameFormComponent implements OnInit {


  //Vars ====================================
  timeFrameForm: FormGroup;
  timeFrame: TimeFrame;
  isUpdating: boolean = false;
  id: string;
  pointsList: PointDropDownList[];
  linesList: LineDropDownList[];
  projectId: string;
  date: Date;
  enumUnitTypes = Constants.enumUnitTypes;

  //Life cycle
  constructor(
    private router: Router,
    private repo: RepositoryService,
    public datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');
    console.log('projectId= ', this.projectId);
    this.PointGetDropDownList();
  }


  //Form Create

  public createtimeFramesForm() {
    this.date = new Date(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
    this.timeFrameForm = new FormGroup({
      id: new FormControl(null),
      accumulatedAmount: new FormControl(0),
      accumulatedPercentage: new FormControl(0),

      point: new FormControl(this.pointsList[0].id),
      line: new FormControl(this.linesList[0].id),

      date: new FormControl(this.date),
      unitType: new FormControl(this.enumUnitTypes[0].value),
    });
  }

  public onSubmit = (timeFrameForm) => {
    console.log('accumulatedAmount', timeFrameForm.accumulatedAmount);
    if (this.timeFrameForm.valid) {
      if (this.isUpdating) {
        //this.timeFrameUpdate(timeFrameForm);
      }
      else {
        this.timeFrameCreate(timeFrameForm);
      }
    }
  }


  //Database ===================================
  public PointGetDropDownList = () => {
    const points$ = this.activeRoute.paramMap.pipe(
      map(param => param.get('projectId')),
      tap(result => console.log('projectId', result)),
      switchMap(projectId => this.repo.getData(`api/Points/PointsGetDropDownListForTimeFrame?projectId=${projectId}`)),
      tap(result => console.log('Points', result)),
    )

    points$.subscribe(response => {
      this.pointsList = response as PointDropDownList[]
      this.lineGetDropDownList();
    }, (error) => {
      console.log(error);
    })



  }


  public lineGetDropDownList = () => {
    const points$ = this.activeRoute.paramMap.pipe(
      map(param => param.get('projectId')),
      tap(result => console.log('projectId', result)),
      switchMap(projectId => this.repo.getData(`api/lines/LinesGetDropDownListByProjectId?projectId=${projectId}`)),
      tap(result => console.log('Points', result)),
    )

    points$.subscribe(response => {
      this.linesList = response as LineDropDownList[]
      this.createtimeFramesForm();
      if (this.id != null) {
        this.isUpdating = true;
        this.timeFrameGetById(this.id);
      }
      console.log(response);
    }, (error) => {
      console.log(error);
    })



  }


  private timeFrameCreate = (timeFrameFormValue) => {
    let timeFrame: TimeFrame
    //point
    if (timeFrameFormValue.unitType == 0) {
      timeFrame = {
        accumulatedAmount: timeFrameFormValue.accumulatedAmount,
        accumulatedPercentage: timeFrameFormValue.accumulatedPercentage,
        pointId: timeFrameFormValue.point,
        lineId: null,
        date: timeFrameFormValue.date
      }


    }
    else{
      timeFrame = {
        accumulatedAmount: timeFrameFormValue.accumulatedAmount,
        accumulatedPercentage: timeFrameFormValue.accumulatedPercentage,
        pointId: null,
        lineId: timeFrameFormValue.line,
        date: timeFrameFormValue.date
      }
    }
    console.log(timeFrame);
    this.repo.create('api/timeFrame/TimeFrameCreate', timeFrame)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public timeFrameGetById = (id: string) => {
    this.repo.getData('api/timeFrame/timeFrameGetById?id=' + id).subscribe(response => {
      this.timeFrame = response as TimeFrame
      // this.timeFrameForm.patchValue(this.timeFrame);
      this.timeFrameForm.get('id').setValue(this.timeFrame.id);
      this.timeFrameForm.get('accumulatedAmount').setValue(this.timeFrame.accumulatedAmount);
      this.timeFrameForm.get('accumulatedPercentage').setValue(this.timeFrame.accumulatedPercentage);


      if (this.timeFrame.pointId == null) {
        this.timeFrameForm.get('unitType').setValue(this.enumUnitTypes[1].value);
        this.timeFrameForm.get('line').setValue(this.timeFrame.lineId);
      }
      else {
        this.timeFrameForm.get('unitType').setValue(this.enumUnitTypes[0].value);
        this.timeFrameForm.get('point').setValue(this.timeFrame.pointId);
      }


      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  // public timeFrameUpdate = (timeFrameFormValue) => {
  //   let timeFrame: TimeFrame = {
  //     id: timeFrameFormValue.id,
  //     accumulatedAmount: timeFrameFormValue.accumulatedAmount,
  //     accumulatedPercentage: timeFrameFormValue.accumulatedPercentage,
  //     pointId: timeFrameFormValue.point,
  //   }
  //   console.log(timeFrame);
  //   this.repo.update('api/timeFrame/timeFrameUpdate', timeFrame)
  //     .subscribe(response => {
  //       this.notificationsService.success('تم التعديل بنجاح!');
  //       this.onCancel();
  //       console.log(response);
  //     }, (error) => {
  //       console.log(error);
  //     })
  // }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.timeFrameForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/time-frame-list/' + this.projectId])
  }


}
