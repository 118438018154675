import { PipeTermsType } from './../../_models/PipeTermsType';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RepositoryService } from 'src/app/services/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-pipe-terms-types-list',
  templateUrl: './pipe-terms-types-list.component.html',
  styleUrls: ['./pipe-terms-types-list.component.css']
})
export class PipeTermsTypesListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  projectId: string;
  //Table ==================================================

  public displayedColumns = ['name', 'projectName', 'update', 'delete'];
  public dataSource = new MatTableDataSource<PipeTermsType>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private repo: RepositoryService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');
    this.PipeTermsTypessGet()
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }


  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.PipeTermsTypessGet();
    }
  }

  //Database =================================================
  public PipeTermsTypessGet = () => {
    this.repo.getData('api/PipeTermsTypess/PipeTermsTypessGet?projectId=' + this.projectId)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as PipeTermsType[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public PipeTermsTypessSearch = (name: string) => {
    this.query = 'api/PipeTermsTypess/PipeTermsTypessSearch?name=' + name + '&projectId=' + this.projectId;
    console.log(this.query);
    if (name != null) {
      this.repo.getData('api/PipeTermsTypess/PipeTermsTypessSearch?name=' + name)
        .subscribe(response => {
          this.isLoading = true;
          this.dataSource.data = response as PipeTermsType[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف النوع؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/PipeTermsTypess/PipeTermsTypesDelete?id=' + id).subscribe(response => {
          this.PipeTermsTypessGet();
          this.notificationService.delete('تم حذف النوع بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.PipeTermsTypessSearch(value)
  }



}
