import { RoomTerm } from './../../_models/RoomTerm';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-rooms-terms-form',
  templateUrl: './rooms-terms-form.component.html',
  styleUrls: ['./rooms-terms-form.component.css']
})
export class RoomsTermsFormComponent implements OnInit {

  //Vars ====================================
  roomsTermsForm: FormGroup;
  roomsTerm: RoomTerm;
  roomDivisionId: string;
  id: string;
  isUpdating: boolean = false;

  //roomTermsDropDownList: roomTermDropDownList[] = [];

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    // this.roomsTermsGetDropDownList();
    this.createroomsTermssForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.roomDivisionId = this.activeRoute.snapshot.params['roomDivisionId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.roomsTermsGetById(this.id);
    }
  }


  public createroomsTermssForm() {
    this.roomsTermsForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      price: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      maxQuantity: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      unit: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      fraction: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      priority: new FormControl(0, [Validators.required]),
    });
  }

  public onSubmit = (roomsTermsFormValue) => {
    if (this.roomsTermsForm.valid) {
      if (this.isUpdating) {
        this.roomsTermsUpdate(roomsTermsFormValue);
      }
      else {
        this.roomsTermsCreate(roomsTermsFormValue);
      }
    }
  }

  private roomsTermsCreate = (roomsTermsFormValue) => {
    let roomsTerms: RoomTerm = {
      name: roomsTermsFormValue.name,
      price: roomsTermsFormValue.price,
      roomDivisionId: this.roomDivisionId,
      unit: roomsTermsFormValue.unit,
      maxQuantity: roomsTermsFormValue.maxQuantity,
      fraction: roomsTermsFormValue.fraction,
      priority: roomsTermsFormValue.priority
    }
    console.log(roomsTerms);
    this.repo.create('api/roomTerms/roomTermsCreate', roomsTerms)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  // public roomsTermsGetDropDownList = () => {
  //   this.repo.getData('api/roomTerms/roomTermsGetDropDownList')
  //     .subscribe(response => {
  //       this.roomTermsDropDownList = response as roomTermDropDownList[];
  //       console.log('roomTermsDropDownList',this.roomTermsDropDownList)
  //       this.createroomsTermssForm();
  //       console.log(response);

  //     }, (error) => {
  //       console.log(error);
  //     })
  // }


  public roomsTermsGetById = (id: string) => {
    this.repo.getData('api/roomTerms/RoomTermsGetById?id=' + id).subscribe(response => {
      this.roomsTerm = response as RoomTerm
      // this.roomsTermsForm.patchValue(this.roomsTerms);
      this.roomsTermsForm.get('id').setValue(this.roomsTerm.id);
      this.roomsTermsForm.get('name').setValue(this.roomsTerm.name);
      this.roomsTermsForm.get('roomDivisionId').setValue(this.roomsTerm.roomDivisionId);
      this.roomsTermsForm.get('unit').setValue(this.roomsTerm.unit);
      this.roomsTermsForm.get('maxQuantity').setValue(this.roomsTerm.maxQuantity);
      this.roomsTermsForm.get('fraction').setValue(this.roomsTerm.fraction);
      this.roomsTermsForm.get('priority').setValue(this.roomsTerm.priority);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public roomsTermsUpdate = (roomsTermsFormValue) => {
    let roomsTerms: RoomTerm = {
      id: roomsTermsFormValue.id,
      name: roomsTermsFormValue.name,
      roomDivisionId: this.roomDivisionId,
      unit: roomsTermsFormValue.unit,
      maxQuantity: roomsTermsFormValue.maxQuantity,
      fraction: roomsTermsFormValue.fraction,
      priority: roomsTermsFormValue.priority,
    }
    console.log(roomsTerms);
    this.repo.update('api/roomTerms/roomTermUpdate', roomsTerms)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.roomsTermsForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/rooms-terms-list/' + this.roomDivisionId])
  }

}
