<!-- Loading Indicator -->
<div *ngIf="!pointTermForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة بند جديد</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="pointTermForm" *ngIf="pointTermForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(pointTermForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم البند" formControlName="name" id="name">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="السعر" formControlName="price" id="price">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="الوحدة" formControlName="unit" id="unit">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="اقصى كمية" formControlName="maxQuantity" id="maxQuantity">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!pointTermForm.valid">
                                {{ isUpdating == true ? 'تعديل البند' : 'اضافة البند' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>