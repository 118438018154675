<!-- Loading Indicator -->
<div *ngIf="!requestForm || isLoading"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة طلب جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="requestForm" *ngIf="requestForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(requestForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم الطلب" formControlName="title" id="title">
                                <mat-error *ngIf="requestForm && hasError('title', 'required')">هذا الحقل مطلوب
                                </mat-error>
                                <mat-error *ngIf="requestForm && hasError('title', 'maxlength')">الاسم اكثر من 200 حرف
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="من" formControlName="requestFrom" id="requestFrom">                              
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="الى" formControlName="requestTo" id="requestTo">                              
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="رقم الطلب" formControlName="requestNumber" id="requestNumber">
                                <mat-error *ngIf="requestForm && hasError('title', 'required')">هذا الحقل مطلوب
                                </mat-error>
                                <mat-error *ngIf="requestForm && hasError('title', 'maxlength')">الاسم اكثر من 200 حرف
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>نوع الطلب</mat-label>
                                <mat-select formControlName="requestType" id="requestType">
                                    <mat-option *ngFor="let requestType of requestTypesList" [value]="requestType.id">
                                        {{requestType.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>اسم النقطة</mat-label>
                                <mat-select formControlName="pointName" id="pointName">
                                    <mat-option *ngFor="let point of pointsList" [value]="point.name">
                                        {{point.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>مرحلة التقديم</mat-label>
                                <mat-select formControlName="submitLevel" id="submitLevel">
                                    <mat-option *ngFor="let submitLevel of enumRequestSubmitLevel"
                                        [value]="submitLevel.value">
                                        {{ submitLevel.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>النوع</mat-label>
                                <mat-select formControlName="engineeringType" id="engineeringType">
                                    <mat-option *ngFor="let engineeringType of enumEngineeringType"
                                        [value]="engineeringType.value">
                                        {{ engineeringType.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-md-3">
                                <mat-label>نموذج التقديم</mat-label>
                                <input type="file" #file accept="application/pdf" (change)="selectFile(file.files)">
                            </div>
                            <div class="col-md-4">
                                <span class="upload" *ngIf="progress > 0">
                                    {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message">
                                    {{message}}
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="col-md-3">
                                <mat-label>المرفقات</mat-label>
                                <input type="file" multiple #file2 (change)="selectMultiFiles($event)">
                            </div>
                            <div class="col-md-4">
                                <span class="upload" *ngIf="progress > 0">
                                    {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message">
                                    {{message}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <br>
                    <div class="row">

                    </div>







                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!requestForm.valid">
                                {{ isUpdating == true ? 'تعديل الطلب' : 'اضافة الطلب' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>