import { environment } from './../../../environments/environment';
import { RequestModel } from './../../_models/RequestModel';
import { RequestTypeDropDownList } from './../../_models/RequestTypeDropDownList';
import { Constants } from 'src/app/Constants';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { PointDropDownList } from 'src/app/_models/PointDropDownList';

@Component({
  selector: 'app-requests-form',
  templateUrl: './requests-form.component.html',
  styleUrls: ['./requests-form.component.css']
})
export class RequestsFormComponent implements OnInit {

  //Vars ====================================
  requestForm: FormGroup;
  formData: FormData;
  multiformData: FormData;
  request: RequestModel;
  requestTypesList: RequestTypeDropDownList[];
  pointsList: PointDropDownList[];
  isUpdating: boolean = false;
  id: string;
  requestId: string = '';
  isLoading: boolean;
  public progress: number;
  public message: string;
  enumRequestSubmitLevel = Constants.enumRequestSubmitLevel;
  enumEngineeringType = Constants.enumEngineeringType;

  selectedFiles: FileList;

  //Uploader=================================
  uploader: FileUploader;
  hasBaseDropZoneOver: false;
  baseUrl = environment.urlAddress;


  //=========================================

  //enumrequestTypes =  EnumrequestType;
  enumRequestResponse = Constants.enumRequestReponse;
  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private http: HttpClient,
    public notificationsService: NotificationsService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.PointsGetDropDownListFormRequestList();
    //this.requestTypeGetDropDownList();
  }



  public createRequestsForm() {
    this.requestForm = new FormGroup({
      id: new FormControl(null),
      title: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      requestNumber: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      requestFrom: new FormControl(''),
      requestTo: new FormControl(''),
      requestType: new FormControl(this.requestTypesList[0].id),
      file: new FormControl([null, Validators.required]),
      submitLevel: new FormControl(this.enumRequestSubmitLevel[0].value),
      engineeringType: new FormControl(this.enumEngineeringType[0].value),
      pointName: new FormControl(this.pointsList[0].name, [Validators.required]),
    });
  }

  public onSubmit = (requestFormValue) => {
    if (this.requestForm.valid) {
      if (this.isUpdating) {
        //this.RequestUpdate(RequestFormValue);
      }
      else {
        this.requestCreate(requestFormValue);
      }
    }
  }

  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    this.formData = new FormData();
    this.formData.append('file', fileToUpload, fileToUpload.name);
  }


  public selectMultiFiles = (event) => {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles.length === 0) {
      console.log('No files');
      return;
    }
    this.multiformData = new FormData();
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.multiformData.append('file', this.selectedFiles[i], this.selectedFiles[i].name);
      console.log('filename is:/ ');
      console.log(this.selectedFiles[i].name);
    }


    // this.selectedFiles.forEach(file => {
    //   let fileToUpload = <File>file;

    //   this.formData.append('file', fileToUpload, fileToUpload.name);
    // });   
  }

  public uploadMutlibleFiles() {
    this.http.post(this.baseUrl + '/api/requests/requestMultiUpload?requestId=' + this.requestId
      , this.multiformData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          console.log('here3');
          this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }

  public uploadFile() {
    this.http.post(this.baseUrl + '/api/requests/requestUploadPdf?requestId=' + this.requestId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles ) {
          console.log('here3')
          this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }

  //Database ===================================

  public PointsGetDropDownListFormRequestList = () => {
    this.repo.getData('api/points/PointsGetDropDownListForRequestList?userId=' + localStorage.getItem('userId'))
      .subscribe(response => {
        this.pointsList = response as PointDropDownList[];
        this.requestTypeGetDropDownList();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public requestTypeGetDropDownList = () => {
    this.repo.getData('api/requestTypes/requestTypesGetDropDownList?userId=' + localStorage.getItem('userId'))
      .subscribe(response => {
        this.requestTypesList = response as RequestTypeDropDownList[]
        this.createRequestsForm();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  private requestCreate = (requestFormValue) => {
    this.isLoading = true;
    let request: RequestModel = {
      title: requestFormValue.title,
      requestNumber: requestFormValue.requestNumber,
      requestTypeId: requestFormValue.requestType,
      submitLevel: requestFormValue.submitLevel,
      contractorId: localStorage.getItem('userId'),
      engineeringType : requestFormValue.engineeringType,
      pointName : requestFormValue.pointName,
      requestFrom : requestFormValue.requestFrom,
      requestTo : requestFormValue.requestTo,
    }
    console.log(request);
    this.repo.create('api/requests/requestCreate', request).subscribe(response => {
      let request: RequestModel = response as RequestModel;
      this.requestId = request.id;
      this.uploadFile()
      console.log('here1')
      if (this.selectedFiles) {
        console.log('here2')
        this.uploadMutlibleFiles();
      }

      // this.isLoading = false;
      // this.notificationsService.success('تمت الاضافة بنجاح!');
      // this.onCancel();

      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  private requestUploadPdf = (requestId) => {
    console.log(requestId);
    this.repo.create('api/requests/requestUploadPdf?requestId=' + this.requestId, null).subscribe(response => {
      let request: RequestModel = response as RequestModel;
      this.notificationsService.success('تمت الاضافة بنجاح!');
      this.onCancel();
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }





  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.requestForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/requests'])
  }


}
