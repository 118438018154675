import { Point } from './../../_models/Point';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-points-form',
  templateUrl: './points-form.component.html',
  styleUrls: ['./points-form.component.css']
})
export class PointsFormComponent implements OnInit {
  //Vars ====================================
  pointForm: FormGroup;
  point: Point;
  unitId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.createpointsForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.unitId = this.activeRoute.snapshot.params['unitId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.pointGetById(this.id);
    }
  }

  //Form Create

  public createpointsForm() {
    this.pointForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      latitude: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      longitude: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
  }

  public onSubmit = (pointFormValue) => {
    if (this.pointForm.valid) {
      if (this.isUpdating) {
        this.pointUpdate(pointFormValue);
      }
      else {
        this.pointCreate(pointFormValue);
      }
    }
  }

  private pointCreate = (pointFormValue) => {
    let point: Point = {
      name: pointFormValue.name,
      latitude: pointFormValue.latitude,
      longitude: pointFormValue.longitude,
      unitId: this.unitId,
    }
    console.log(point);
    this.repo.create('api/points/pointCreate', point)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public pointGetById = (id: string) => {
    this.repo.getData('api/points/pointGetById?id=' + id).subscribe(response => {
      this.point = response as Point
      // this.pointForm.patchValue(this.point);
      this.pointForm.get('id').setValue(this.point.id);
      this.pointForm.get('name').setValue(this.point.name);
      this.pointForm.get('latitude').setValue(this.point.latitude);
      this.pointForm.get('longitude').setValue(this.point.longitude);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pointUpdate = (pointFormValue) => {
    let point: Point = {
      id: pointFormValue.id,
      name: pointFormValue.name,
      latitude: pointFormValue.latitude,
      longitude: pointFormValue.longitude,
      unitId: this.unitId,
    }
    console.log(point);
    this.repo.update('api/points/pointUpdate', point)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pointForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/points/' + this.unitId])
  }

}
