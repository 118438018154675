import { Constants } from '../../Constants';
import { EnumUnitType } from './../../_models/_enums/EnumUnitType';
import { ProjectDropDownList } from './../../_models/ProjectDropDownList';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { Unit } from 'src/app/_models/Unit';
import { Location } from '@angular/common';
import { Project } from 'src/app/_models/Project';

@Component({
  selector: 'app-units-form',
  templateUrl: './units-form.component.html',
  styleUrls: ['./units-form.component.css']
})
export class UnitsFormComponent implements OnInit {

  //Vars ====================================
  unitForm: FormGroup;
  unit: Unit;
  isUpdating: boolean = false;
  id: string;
  projectsList: ProjectDropDownList[];
  //enumUnitTypes =  EnumUnitType;
  enumUnitTypes = Constants.enumUnitTypes;
  projectId:string;


  //Life cycle
  constructor(private location: Location,
    private router : Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    // this.projectGetDropDownList();
    this.createUnitsForm();
    this.id = this.activeRoute.snapshot.params['id'];
    this.projectId = this.activeRoute.snapshot.params['projectId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.unitGetById(this.id);
    }
  }

  ngAfterViewInit(): void {

  }

  //Form Create

  public createUnitsForm() {
    this.unitForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      unitType: new FormControl(this.enumUnitTypes[0].value),
      // project: new FormControl(this.projectsList[0].id)
    });
  }

  public onSubmit = (unitFormValue) => {
    if (this.unitForm.valid) {
      if (this.isUpdating) {
        this.unitUpdate(unitFormValue);
      }
      else {
        this.unitCreate(unitFormValue);
      }
    }
  }

  //Database ===================================
  public projectGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList').subscribe(response => {
      this.projectsList = response as ProjectDropDownList[]
      this.createUnitsForm();
      if (this.id != null) {
        this.isUpdating = true;
        this.unitGetById(this.id);
      }
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }



  private unitCreate = (unitFormValue) => {
    let unit: Unit = {
      name: unitFormValue.name,
      unitType: unitFormValue.unitType,
      projectId: this.projectId,
    }
    console.log(unit);
    this.repo.create('api/units/unitCreate', unit)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public unitGetById = (id: string) => {
    this.repo.getData('api/units/unitGetById?id=' + id).subscribe(response => {
      this.unit = response as Unit
     // this.unitForm.patchValue(this.unit);
      this.unitForm.get('id').setValue(this.unit.id);
      this.unitForm.get('name').setValue(this.unit.name);
      // this.unitForm.get('project').setValue(this.unit.projectId);
      this.unitForm.get('unitType').setValue(this.enumUnitTypes[this.unit.unitType].value);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public unitUpdate = (unitFormValue) => {
    let unit: Unit = {
      id: unitFormValue.id,
      name: unitFormValue.name,
      unitType: unitFormValue.unitType,
      projectId: this.projectId,
    }
    console.log(unit);
    this.repo.update('api/units/unitUpdate', unit)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.unitForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/units/'+this.projectId])
  }

}
