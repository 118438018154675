<div class="gradient-Header">
    <div class="gradient-child">
        <h3>أعمال الغرف والمناهل</h3>
    </div>
</div>



<div class="row" style="margin-top: 10px; justify-content: space-around;">
  <div class="col-md-2">
    <mat-form-field>
        <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text" placeholder="بحث">
    </mat-form-field>
</div>

    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
                (dateChange)="changeFromDate($event.value)" placeholder="من">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="col-md-2">
        <mat-form-field>
            <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo"
                (dateChange)="changeToDate($event.value)" placeholder="الى">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="col-md-2">
    <button mat-raised-button (click)="search(searchText)" color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>
    </div>
    <div class="col-md-2">

    <button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
        <mat-icon>search</mat-icon>
        عرض الكل
    </button>
    </div>
</div>


<div class="row">
    <div class="col-md-3 mb-2">
        <button [routerLink]="['/rooms-entries-form']" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>
</div>


<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->

        <ng-container matColumnDef="lineName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>الخط</th>
            <td mat-cell *matCellDef="let element"> {{element.lineName}} </td>
        </ng-container>

        <ng-container matColumnDef="roomDivisionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>القسم</th>
            <td mat-cell *matCellDef="let element"> {{element.roomDivisionName}} </td>
        </ng-container>

        <ng-container matColumnDef="roomTermName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> البند </th>
            <td mat-cell *matCellDef="let element"> {{element.roomTermName}} </td>
        </ng-container>


        <ng-container matColumnDef="roomStationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> المحطة </th>
            <td mat-cell *matCellDef="let element"> {{element.roomStationName}} </td>
        </ng-container>


        <ng-container matColumnDef="roomTypeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> نوع الغرفة </th>
            <td mat-cell *matCellDef="let element"> {{element.roomTypeName}} </td>
        </ng-container>



        <ng-container matColumnDef="roomTermPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> سعر البند </th>
            <td mat-cell *matCellDef="let element"> {{element.roomTermPrice}} </td>
        </ng-container>

        <ng-container matColumnDef="roomTypePrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> سعر الغرفة </th>
            <td mat-cell *matCellDef="let element"> {{element.roomTypePrice}} </td>
        </ng-container>

        <ng-container matColumnDef="roomTermFraction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> معامل الحساب </th>
            <td mat-cell *matCellDef="let element"> {{element.roomTermFraction}} </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> الاجمالي </th>

            <mat-cell *matCellDef="let element">
                <td *ngIf="element.roomTermFraction  < 1"> {{(element.roomTermPrice * element.roomTypePrice) |
                    number:'0.0-2' }} </td>
                <td *ngIf="element.roomTermFraction >= 1"> {{(element.roomTermPrice)| number:'0.0-2' }} </td>
            </mat-cell>

            <!-- <td mat-cell *matCellDef="let element">
                {{element.roomTermPrice * element.roomTypePrice *element.roomTermFraction}}
            </td> -->
        </ng-container>

        <ng-container matColumnDef="inspectorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم المسجل </th>
            <td mat-cell *matCellDef="let element"> {{element.inspectorName}} </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ملاحظات </th>
            <td mat-cell *matCellDef="let element"> {{element.note}} </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> تاريخ الاضافة </th>
            <td mat-cell *matCellDef="let element"> {{element.creationDate | date:'yyyy-MM-dd'}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="photo">
            <th mat-header-cell *matHeaderCellDef>تحميل</th>
            <td mat-cell *matCellDef="let element">
                <a class="btn btn-info" target="_blank" href="{{baseUrl+'/RoomEntryPhotos/'}}{{element.id}}.jpg"
                    download="fileName">
                    تحميل
                </a>
            </td>
        </ng-container>



        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>صورة الاستلام</th>
            <td mat-cell *matCellDef="let element">
                <a color="warn" href="{{baseUrl+'/EntryPhotos/'}}{{fg.value.id}}.jpg" download="fileName">
                    <mat-icon class="mat-18">download</mat-icon>
                </a>
            </td>
        </ng-container> -->


        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>
