<div class="gradient-Header">
    <div class="gradient-child">
        <h3>المشاريع</h3>
    </div>
</div>


<!-- Search form -->

<div fxLayout="row"  class="mt-2 mb-2 search-form " >
    <mat-form-field>
        <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" (keyup)="onKeyUp($event)"
            type="text" placeholder="بحث">
    </mat-form-field>


    <button [disabled]="!searchText" mat-raised-button (click)="search(searchText)" color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>
</div>




<div class="row">
    <div class="col-md-3 mb-2">
        <button [routerLink]="['/projects-form']" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>
</div>




<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم المشروع </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="contractValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> قيمة التعاقد </th>
            <td mat-cell *matCellDef="let element"> {{element.contractValue}} </td>
        </ng-container>

        <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>العميل</th>
            <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
        </ng-container>

        <ng-container matColumnDef="contractorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>المتعاقد</th>
            <td mat-cell *matCellDef="let element"> {{element.contractorName}} </td>
        </ng-container>

        <ng-container matColumnDef="consultantName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>الاستشاري</th>
            <td mat-cell *matCellDef="let element"> {{element.consultantName}} </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> تاريخ البداية </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate | date}} </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> تاريخ النهاية </th>
            <td mat-cell *matCellDef="let element"> {{element.endDate | date}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="showTimeFrame">
            <th mat-header-cell *matHeaderCellDef>المخطط الزمني</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/time-frame-list/'+element.id]">
                    <mat-icon class="mat-18">watch_later</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="showRequestTypes">
            <th mat-header-cell *matHeaderCellDef>انواع الطلبات</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/request-types/'+element.id]">
                    <mat-icon class="mat-18">watch_later</mat-icon>
                </button>
            </td>
        </ng-container>


        <ng-container matColumnDef="showPipeTermsTypes">
            <th mat-header-cell *matHeaderCellDef>انواع بنود الانابيب</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/pipe-terms-types-list/'+element.id]">
                    <mat-icon class="mat-18">watch_later</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="showUnits">
            <th mat-header-cell *matHeaderCellDef>الوحدات</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/units/'+element.id]">
                    <mat-icon class="mat-18">watch_later</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="showRoomTypes">
            <th mat-header-cell *matHeaderCellDef>انواع الغرف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/room-types-list/'+element.id]">
                    <mat-icon class="mat-18">watch_later</mat-icon>
                </button>
            </td>
        </ng-container> -->

          <!-- Buttons -->
          <ng-container matColumnDef="photo1">
            <th mat-header-cell *matHeaderCellDef>صورة العميل</th>
            <td mat-cell *matCellDef="let element">
                <a class="btn btn-info" target="_blank" href="{{baseUrl+'/ProjectClientPhotos/'}}{{element.id}}.jpg"
                    download="fileName">
                    تحميل
                </a>
            </td>
        </ng-container>

          <!-- Buttons -->
          <ng-container matColumnDef="photo2">
            <th mat-header-cell *matHeaderCellDef>صورة المتعاقد</th>
            <td mat-cell *matCellDef="let element">
                <a class="btn btn-info" target="_blank" href="{{baseUrl+'/ProjectContractorPhotos/'}}{{element.id}}.jpg"
                    download="fileName">
                    تحميل
                </a>
            </td>
        </ng-container>


        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>تعديل</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/projects-form/'+element.id]">
                    <mat-icon class="mat-18">system_update</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>
