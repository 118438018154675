import { DialogService } from './../../services/dialog.service';
import { RepositoryService } from './../../services/repository.service';
import { Project } from './../../_models/Project';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationsService } from 'src/app/services/notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {
  baseUrl = environment.urlAddress;
  isLoading = true;
  public displayedColumns = ['name','contractValue', 'startDate', 'endDate','clientName','consultantName',
  'contractorName', 'showTimeFrame','showUnits','showRequestTypes','showPipeTermsTypes',
   'photo1', 'photo2', 'update', 'delete'];
  public dataSource = new MatTableDataSource<Project>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchText: string;
  dateFrom: string;
  dateTo: string;
  query: string;

  constructor(private repo: RepositoryService,
    private confirmDialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.projectsGet()
    this.dateFrom = this.currentDate()
  }

  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.projectsGet();
    }
  }


  public projectsGet = () => {
    this.isLoading = true;
    this.repo.getData('api/projects/projectsGet')
      .subscribe(response => {
        this.dataSource.data = response as Project[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public projectsSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/projects/projectsSearch?name=' + name)
        .subscribe(response => {
          this.dataSource.data = response as Project[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
    else {
      this.projectsGet();
    }

  }

  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.projectsSearch(value)
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  public clearSearchBox() {
    this.searchText = ''
  }



  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف المشروع؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/projects/projectDelete?id=' + id).subscribe(response => {
          this.projectsGet();
          this.notificationService.delete('تم حذف المشروع بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

}
