import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-upload-pdf-file-dialog',
  templateUrl: './upload-pdf-file-dialog.component.html',
  styleUrls: ['./upload-pdf-file-dialog.component.css']
})
export class UploadPdfFileDialogComponent implements OnInit {

  requestId: string;
  public progress: number;
  public message: string;
  selectedFiles: FileList;
  formData: FormData;
  baseUrl = environment.urlAddress;

  constructor(public dialogRef: MatDialogRef<UploadPdfFileDialogComponent>,
    public notificationsService: NotificationsService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.requestId = this.data.requestId;
    console.log(this.data.requestId);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    console.log(fileToUpload);
    this.formData = new FormData();
    this.formData.append('file', fileToUpload, fileToUpload.name);
    // this.uploadFile();
  }

  public uploadFile() {
    this.http.post(this.baseUrl + '/api/requests/requestUploadPdf?requestId=' + this.requestId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          // this.dialogRef.close();
          this.notificationsService.success('تم الرفع بنجاح!');
          this.dialogRef.close();
        }
      });
  }

}
