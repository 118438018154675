import { TimeFrame } from './../../_models/TimeFrame';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';
import { PointDropDownList } from 'src/app/_models/PointDropDownList';

@Component({
  selector: 'app-time-frame-list',
  templateUrl: './time-frame-list.component.html',
  styleUrls: ['./time-frame-list.component.css']
})
export class TimeFrameListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  projectId: string;

  //Search

  dateFrom: string;
  dateTo: string;
  dateSearchFrom: string;
  dateSearchTo: string;

  baseUrl = environment.urlAddress;

  //Table ==================================================
  public displayedColumns =
    ['pointName', 'date', 'accumulatedAmount', 'accumulatedPercentage', 'delete'];
  public dataSource = new MatTableDataSource<TimeFrame>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private repo: RepositoryService,
    private confirmDialogService: DialogService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public datePipe: DatePipe,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');

    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()
    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();

    this.timeFramesGet()
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.timeFramesGet();
    }
  }

  //Database =================================================
  public timeFramesGet = () => {
    this.isLoading = true;
    this.repo.getData(`api/timeFrame/TimeFramesGetAll?projectId=${this.projectId}
    &dateFrom=${this.dateSearchFrom}&dateto=${this.dateSearchTo}`)
      .subscribe(response => {
        this.dataSource.data = response as TimeFrame[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }

  public onAddClick() {
    this.isLoading = true;
    const points$ = this.repo.getData(`api/Points/PointsGetDropDownListForTimeFrame?projectId=${this.projectId}`);
    points$.subscribe(response => {
      const points = response as PointDropDownList[]
      if (points == null || points.length == 0) {
        this.notificationService.delete('لا توجد نقط مضافة لهذا المشروع');
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
        this.router.navigate(['/time-frame-form/' + this.projectId])
      }
    })
  }


  public timeFramesSearch = (name: string) => {
    if (name === null || name === undefined) {
      name = '';
    }
    console.log(name);
    this.repo.getData(`api/timeFrame/timeFramesSearch?projectId=${this.projectId}&name=${name}
      &dateFrom=${this.dateSearchFrom}&dateto=${this.dateSearchTo}`)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as TimeFrame[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })

  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف المخطط؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/timeFrame/timeFrameDelete?id=' + id).subscribe(response => {
          this.timeFramesGet();
          this.notificationService.delete('تم حذف المخطط بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.timeFramesSearch(value)
  }

  public clearSearch = (value: string) => {
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
    this.timeFramesGet();
  }

}
